export default function validateCreateTeam(values) {
    let errors = {};
    if (!values.teamname) {
      errors.teamname = "required";
    }

    if (!isNumber(values.girls)) {
      errors.girls = "Only accepts numbers";
    }

    if (!values.country) {
      errors.country = "required";
    }

    if (!values.members) {
      errors.members = "required";
    }

    if (!values.coachs) {
      errors.coachs = "required";
    }

    return errors;
  }


  function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
  // The same using regex:
  // function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }