import React from 'react';
import PropTypes from "prop-types";
import { Route,Switch } from "react-router-dom";
import axios from "axios";
// MUI Stuff
import { makeStyles } from "@material-ui/core/styles";
// Layouts Route
import AppRoute from './AppRoute';
// Layouts
import MainLayout from './Components/layout/Main/MainLayout';
import SignInLayout from './Components/layout/SignInLayout';
// import Reports from './Components/layout/Main/SideMenuPages/Reports';
// Pages
import SignIn from './Components/auth/SignIn';
// import Register from './Components/auth/Register';
import ProjectsList from './Components/projects/ProjectsList';
import Projects from './Components/projects/Projects';
import ProjectDetails from './Components/projects/ProjectDetails';
import Settings from './Components/layout/Main/HeaderPages/Settings';
import Help from './Components/layout/Main/HeaderPages/Help';
import Rankings from './Components/layout/Main/SideMenuPages/Rankings';
import Score from './Components/layout/Main/SideMenuPages/Score';
import CreateProject from './Components/projects/CreateProject';
// import UploadPresentation from './Components/projects/UploadPresentation';
import UploadPresentationAndModel from './Components/projects/UploadPresentationAndModel';
import Challenge from './Components/Challenge'
import Challenge_fr from './Components/Challenge-fr'
// import Mathwork from './Components/Mathwork';
import Team from './Components/projects/Team'
import Judge from './Components/projects/Judge';
// Snackbar stuff
import SuccessSnackbar from "./Components/snackbar/SuccessSnackbar";
// Redux Stuff
import { connect } from "react-redux";
import store from "./Redux/Store";
import { signout } from "./Redux/Actions/Auth";
import { SET_USER_DETAILS } from "./Redux/Types";

const useStyles = makeStyles({
  container : {
    display: "flex"
  }
});

axios.defaults.baseURL = "https://parcrobotics.org/index.php?option=com_games&task=games.loginExhibit";
// axios.defaults.headers.common = {
//   ...axios.defaults.headers.common,
//   'Access-Control-Allow-Origin': 'https://makers.parcrobotics.org',
//   "Content-Type": 'application/x-www-form-urlencoded'
// };
// axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// Local storage for verification auth
const storeLocal = localStorage.PARCUserData;
if(storeLocal) {
  // Convert string to JSON
  const userDetails = JSON.parse(storeLocal);
  // Set user details
  store.dispatch({
    type: SET_USER_DETAILS,
    payload: userDetails
  });
} else {
  store.dispatch(signout());
}

function App() {
    const classes = useStyles();

    return (
      <div className="App">
        <div className={classes.container}>
          <SuccessSnackbar/>

          <Switch>
            <AppRoute exact path="/" layout={MainLayout} component={Challenge} />
            <AppRoute exact path="/french" layout={MainLayout} component={Challenge_fr} />
            <Route exact path="/matworks" component={() => {
              window.location.href = "https://www.mathworks.com/academia/student-competitions/PARC.html";
              return null;
            }}   />
            <AppRoute exact path="/upload" layout={MainLayout} component={CreateProject} />
            <AppRoute exact path="/project/details/:idProject" layout={MainLayout} component={UploadPresentationAndModel} />
            <AppRoute exact path="/ideas" layout={MainLayout} component={ProjectsList} />
            <AppRoute exact path="/projects" layout={MainLayout} component={Projects} />
            <AppRoute exact path="/details/:idProject" layout={MainLayout} component={ProjectDetails} />
            <AppRoute exact path="/team" layout={MainLayout} component={Team} />
            <AppRoute exact path="/signin" layout={SignInLayout} component={SignIn} />
            {/* <AppRoute exact path="/register" layout={SignInLayout} component={Register} /> */}
            <AppRoute exact path="/settings" layout={MainLayout} component={Settings} />
            <AppRoute exact path="/help" layout={MainLayout} component={Help} />
            <AppRoute exact path="/rankings" layout={MainLayout} component={Rankings} />
            <AppRoute exact path="/score" layout={MainLayout} component={Score} />
            <AppRoute exact path="/judge" layout={MainLayout} component={Judge} />
          </Switch>
        </div>
      </div>
    );
  }

  App.propTypes = {
    signout: PropTypes.func.isRequired
  };

  const mapDispatchToProps = dispatch => {
    return {
      signout: () => dispatch(signout())
    };
  };

  export default connect(null, mapDispatchToProps)(App);