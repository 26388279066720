import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
// Reusable component
import useForm from "../../Utils/UseForm";
import validate from "../../Utils/formValidations/validateRanking";
// MUI Stuff
import { makeStyles, ThemeProvider, createMuiTheme,} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import Link from '@material-ui/core/Link';
// Redux stuff
import { connect } from "react-redux";
import { getProjects } from "../../Redux/Actions/Project";
import { createRanking } from "../../Redux/Actions/Ranking";

const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0025FF", },
    secondary: {
        main: "#FF6400",
          },},
  });

const useStyles = makeStyles((theme) => ({
  content: {
    width: "80%",
    height: '100vh',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  root: {
    width: '70%',
    marginLeft: 150,
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      marginLeft: 90
    }
  },
  container: {
    maxHeight: 450,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "70%",
    marginLeft: 150,
    [theme.breakpoints.down("md")]: {
      marginLeft: 90
    }
  },
  textDescription: {
    marginLeft: 150,
    marginBottom: 14,
    [theme.breakpoints.down("md")]: {
      marginLeft: 50,
      textAlign: 'center'
    }
  },
  submit: {
    color: "white",
    backgroundColor: "#0025FF",
    height: "40px",
    width: "175px",
    marginLeft: "390px",
    fontSize: '16px',
    marginTop: 11,
    [theme.breakpoints.down("md")]: {
      marginLeft: 150
    }
},
text :{
    marginTop: 20,
    marginLeft: 95,
    marginRight: -300,
    align: 'center',
}
}));

function Judge(props) {
  const { projects, user } = props;
  const classes = useStyles();
  // console.log(user.credentials);

  // ComponentDidMount Hoock
  useEffect(() => {
    props.getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set default form values
  let formValues = {
    projectname: "",
    videoContent: "",
    clarity: "",
    teamwork: "",
    contentOnePageSummary: "",
    aestheticOnePageSummary: "",
    organization: "",
    empathize: "",
    define: "",
    ideate: "",
    model: "",
    proDefinition: "",
    lifPriorities: "",
    comment: "",
    whoSolution: "",
    refSolution: ""
  };
  // call use form
  const {
    handleChange,
    handleSubmit,
    values,
    errors
  } = useForm(submit, validate, formValues);

  // Redirect if User login
  //  if (!user.isAuthenticated || user.credentials.role !== 'judge') return <Redirect to="/signin" />;
   if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // Submit Rakings
  function submit() {
      // fill data
      const newRanking = {
        projectId: values.projectname ? values.projectname.trim() : null,
        videoContent: values.videoContent ? values.videoContent.trim() : 0,
        clarity: values.clarity ? values.clarity.trim() : 0,
        teamwork: values.teamwork ? values.teamwork.trim() : 0,
        contentOnePageSummary: values.contentOnePageSummary ? values.contentOnePageSummary.trim() : 0,
        aestheticOnePageSummary: values.aestheticOnePageSummary ? values.aestheticOnePageSummary.trim() : 0,
        organization: values.organization ? values.organization.trim() : 0,
        empathize: values.empathize ? values.empathize.trim() : 0,
        define: values.define ? values.define.trim() : 0,
        ideate: values.ideate ? values.ideate.trim() : 0,
        model: values.model ? values.model.trim() : 0,
        proDefinition: values.proDefinition ? values.proDefinition.trim() : 0,
        lifPriorities: values.lifPriorities ? values.lifPriorities.trim() : 0,
        comment: values.comment ? values.comment.trim() : 0,
        whoSolution: values.whoSolution ? values.whoSolution.trim() : 0,
        refSolution: values.refSolution ? values.refSolution.trim() : 0,
        userId: user.credentials.userId
        // userId: "02312"
      };
      // Handling errors
      // console.log(newRanking);
      props.createRanking(newRanking);
  }

  return (
      <div className={classes.content}>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit} >
          <ThemeProvider theme={theme}>
          <Typography variant="subtitle2" gutterBottom className={classes.textDescription}>Each deliverable will receive a score between 2-10: (10)- Exemplary, (8)- Competent, (6)- Developing, (4)- Marginal, (2)-Needs Major Support <br/>
          
           </Typography>

            <FormControl className={classes.formControl} variant="outlined" error={errors.projectname ? true : false}>
              <InputLabel htmlFor="outlined-projectname-native-simple">Project name *</InputLabel>
              <Select
                native
                label="Project name *"
                value={values.projectname}
                onChange={handleChange}
                inputProps={{
                  name: 'projectname',
                  id: 'outlined-projectname-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                {projects && projects.map(project => (
                  <option value={project.projectId} key={project.projectId}>{project.name}</option>
                ))}
              </Select>
              {errors.projectname ? <FormHelperText>required</FormHelperText> : null}
            </FormControl>
            {/* video */}
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell style={{ minWidth: 340, color: "#FF6400", fontWeight: 'bold'}}
                        >
                       VIDEO
                        </TableCell>
                        <TableCell style={{ minWidth: 70, color: "#FF6400", fontWeight: 'bold'}}
                        >
                          SCORE
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">CONTENT</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="videoContent"
                          label="score videoContent"
                          name="videoContent"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.videoContent ? true : false}
                          helperText={errors.videoContent}
                          value={values.videoContent}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">CLARITY</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="clarity"
                          label="score clarity"
                          name="clarity"
                          autoComplete="clarity"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.clarity ? true : false}
                          helperText={errors.clarity}
                          value={values.clarity}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">TEAM WORK</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="teamwork"
                          label="score teamwork"
                          name="teamwork"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.teamwork ? true : false}
                          helperText={errors.teamwork}
                          value={values.teamwork}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
  
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
           {/* onePageSummary */}
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell style={{ minWidth: 340, color: "#FF6400", fontWeight: 'bold'}}
                        >
                       ONE PAGE SUMMARY
                        </TableCell>
                        <TableCell style={{ minWidth: 70, color: "#FF6400", fontWeight: 'bold'}}
                        >
                          SCORE
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">CONTENT</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="contentOnePageSummary"
                          label="score content"
                          name="contentOnePageSummary"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.contentOnePageSummary ? true : false}
                          helperText={errors.contentOnePageSummary}
                          value={values.contentOnePageSummary}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">AESTHETIC</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="aestheticOnePageSummary"
                          label="score aesthetic"
                          name="aestheticOnePageSummary"
                          autoComplete="aestheticOnePageSummary"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.aestheticOnePageSummary ? true : false}
                          helperText={errors.aestheticOnePageSummary}
                          value={values.aestheticOnePageSummary}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">ORGANIZATION</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="organization"
                          label="score organization"
                          name="organization"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.organization ? true : false}
                          helperText={errors.organization}
                          value={values.organization}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
  
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {/* design THINKING */}
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={classes.head}>
                    <TableRow>
                        <TableCell style={{ minWidth: 340, color: "#FF6400", fontWeight: 'bold'}}
                        >
                          DESIGN THINKING METHODOLOGY
                        </TableCell>
                        <TableCell style={{ minWidth: 70, color: "#FF6400", fontWeight: 'bold'}}
                        >
                          SCORE
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">EMPATHIZE</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="empathize"
                          label="score empathize"
                          name="empathize"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.empathize ? true : false}
                          helperText={errors.empathize}
                          value={values.empathize}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">DEFINE</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="define"
                          label="score define"
                          name="define"
                          autoComplete="define"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.define ? true : false}
                          helperText={errors.define}
                          value={values.define}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">IDEATE</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="ideate"
                          label="score ideate"
                          name="ideate"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.ideate ? true : false}
                          helperText={errors.ideate}
                          value={values.ideate}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">MODEL</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="model"
                          label="score model"
                          name="model"
                          autoComplete="score"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.model ? true : false}
                          helperText={errors.model}
                          value={values.model}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {/* WHOLE SYSTEMS */}
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 170, color: "#FF6400", fontWeight: 'bold'}}
                      >
                        WHOLE SYSTEMS & LIFE CYCLE METHODOLOGY
                      </TableCell>
                      <TableCell style={{ minWidth: 70, color: "#FF6400", fontWeight: 'bold'}}
                      >
                        SCORE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">PROBLEM DEFINITION</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="proDefinition"
                          label="score"
                          name="proDefinition"
                          autoComplete="proDefinition"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.proDefinition ? true : false}
                          helperText={errors.proDefinition}
                          value={values.proDefinition}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">LIFE CYCLE PRIORITIES CONSIDERATION</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="lifPriorities"
                          label="score"
                          name="lifPriorities"
                          autoComplete="lifPriorities"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.lifPriorities ? true : false}
                          helperText={errors.lifPriorities}
                          value={values.lifPriorities}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">WHOLE SYSTEMS SOLUTION CONSIDERATION</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="whoSolution"
                          label="score"
                          name="whoSolution"
                          autoComplete="whoSolution"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.whoSolution ? true : false}
                          helperText={errors.whoSolution}
                          value={values.whoSolution}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">REFLECT ON REDESIGN SOLUTION</TableCell>
                      <TableCell align="left">
                        <TextField variant="outlined"
                          id="refSolution"
                          label="score"
                          name="refSolution"
                          autoComplete="refSolution"
                          size="small"
                          width='2px'
                          className={classes.textField}
                          error={errors.refSolution ? true : false}
                          helperText={errors.refSolution}
                          value={values.refSolution}
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper className={classes.root}>
             
            <TextField
                id="comment"
                name="comment"
                type="text"
                label="Comment"
                placeholder="Comment"
                rows={4}
                variant="filled"
                autoComplete="comment"
                className={classes.textField}
                error={errors.comment ? true : false}
                helperText={errors.comment}
                value={values.comment}
                onChange={handleChange}
                fullWidth
                multiline
              />

            </Paper>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              className={classes.submit}
            >
              Submit Scores
            </Button>
          </ThemeProvider>
        </form>
      </div>
  )
};

Judge.propTypes = {
  getProjects: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  projects: state.projectData.projects,
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects()),
    createRanking: (newRanking) => dispatch(createRanking(newRanking))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Judge));
