import React from 'react';
import { withRouter } from 'react-router-dom';
// MUI stuff
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
// Redux stuff
import { connect } from "react-redux";
import { signout } from "../../../Redux/Actions/Auth";

const useStyles = makeStyles((theme) => ({
root:{
  display: "contents",
}

}));

function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMenuClick = (pageURL) => {
  //   history.push(pageURL);
  //   setAnchorEl(null);
  // };

  const handleLogout = () => {
    props.signout()
  };

  return (
      <div className={classes.root}>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle className={classes.accountCircle}/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={ () => setAnchorEl(null)}
        >
          {/* <MenuItem onClick={ () => handleMenuClick('/settings')}>Settings</MenuItem> */}
          <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
        </Menu>
      </div>
  );
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => {
  return {
    signout: () => dispatch(signout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
