import {
    LOADING_TEAM,
    STOP_LOADING_TEAM,
    CLEAR_ERRORS,
    SET_TEAMS,
  } from "../Types";
  // import axios from "axios";
  import { showSuccessSnackbar } from "./Snackbar";
  // Firebase
  import firebase from "../../config/fire";
  const db = firebase.firestore();

  /**
   * TODO: ALL TeamS ACTIONS
   **/
  //create Team
  export const createTeam = newTeam => {
    return (dispatch, getState) => {
      // Add data
      const newDataTeam = {
        teamname: newTeam.teamname,
        girls: newTeam.girls,
        members: newTeam.members,
        coachs: newTeam.coachs,
        country: newTeam.country,
        picture: newTeam.picture,
        userId: newTeam.userId,
        createdAt: new Date().toISOString(),
        softDelete: false
      };

      if (newTeam.userId) {
        dispatch({ type: LOADING_TEAM });
        db.collection("teams")
          .add(newDataTeam)
          .then(doc => {
            const resTeam = newDataTeam;
            resTeam.teamId = doc.id;
            return resTeam;
          })
          .then(() => {
            dispatch({ type: STOP_LOADING_TEAM });
            dispatch(showSuccessSnackbar("Team added successfully"));
          })
          .catch(err => {
            dispatch({ type: STOP_LOADING_TEAM });
            dispatch(showSuccessSnackbar("Team added error, please try again !"));
          });
      } else {
        // console.log("user not login");
        dispatch({ type: STOP_LOADING_TEAM });
        dispatch(
          showSuccessSnackbar(
            "Try again, something went wrong data user can not be load..."
          )
        );
      }
    };
  };

// Get all Teams
export const getTeams = () => {
    return (dispatch, getState) => {
      // make async call to database
      dispatch({ type: LOADING_TEAM });
      try {
        db.collection("teams")
          .orderBy("createdAt", "desc")
          .where("softDelete", "==", false)
          .onSnapshot(data => {
            let teams = [];
            data.forEach(doc => {
              teams.push({
                teamId: doc.id ? doc.id : null,
                teamname: doc.data().teamname ? doc.data().teamname : null,
                country: doc.data().country ? doc.data().country : null,
                members: doc.data().members ? doc.data().members : null,
                // summary: doc.data().summary ? doc.data().summary : null,
                picture: doc.data().picture ? doc.data().picture : null,
                userId: doc.data().userId ? doc.data().userId : null,
                createdAt: doc.data().createdAt ? doc.data().createdAt : null
              });

              return teams;
            });
            dispatch({ type: SET_TEAMS, payload: teams });
          });
      } catch (error) {}
    };
  };

  /**
   * TODO: GENERAL ACTIONS
   **/
  // Clear error
  export const clearErrors = () => dispatch => {
    dispatch({ type: CLEAR_ERRORS });
  };
