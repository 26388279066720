import firebaseApp from "firebase/app";
import firebase from "../config/fire";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer, reduxFirestore } from "redux-firestore";
import userReducer from "./Reducers/UserReducer";
import projectReducer from "./Reducers/ProjectReducer";
import teamReducer from "./Reducers/TeamReducer";
import uiReducer from "./Reducers/UIReducer";
import rankingReducer from "./Reducers/RankingReducer";

// Create store with reducers and initial state
const initialState = {};

// Spread and ternary operators middleware
let middleware = [applyMiddleware(reduxThunk)];

// Disable redux-logger in production build
if (process.env.NODE_ENV !== "production") {
  middleware = [
    ...middleware,
    ...(window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : [])
  ];
}

const reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: userReducer,
  projectData: projectReducer,
  teamData: teamReducer,
  rankingData: rankingReducer,
  UI: uiReducer
});

const store = createStore(
  reducers,
  initialState,
  compose(...middleware, reduxFirestore(firebaseApp, firebase))
);

export default store;
