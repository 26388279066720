import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import icon from './../../../image/ranking.svg'
// Component
import Image from "../../../../Utils/Image";
// Redux Stuff
import { connect } from "react-redux";
import { getProjectsPublic } from "../../../../Redux/Actions/Ranking";

const useStyles = makeStyles({
  root: {
    // width: '100%',
    // marginLeft: 60,
    marginBottom: 10,
    // marginTop: 10,
  },
  cardMediaStyle: {
    paddingTop: '50%',
  },
  container: {
    maxHeight: 450,
  },
  image:{
    width: "40%",
    marginLeft:"25%"
  },
  text:{
    marginTop:30
  },
  text1:{
    marginLeft:"30%"
  },
  head:{
    backgroundColor:"#FF5C00"
  },
  // bleu:{
  //   backgroundColor:"#8faadc"
  // },
  // red:{
  //   backgroundColor:"#f4b183"
  // },
  // orange:{
  //   backgroundColor:"#fff200"
  // },
  // green:{
  //   backgroundColor:"#a9d18e"
  // }
});

function Ranking(props) {
  const { rankings: {rankingsProject} } = props;
  // console.log(rankingsProject);
  const classes = useStyles();

    // ComponentDidMount Hoock
    useEffect(() => {
      props.getProjectsPublic();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  // if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // const handleButtonClick = (pageURL) => {
  //   history.push(pageURL);
  // }; 

  return (
    <div>
      {/* <Image src={icon} className={classes.image}/>
      <Typography gutterBottom variant="h3" className={classes.text1}>To Be Announced</Typography>  */}
   <Typography variant="h5" className={classes.text}>Final Result</Typography> 

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell align="left">Rank</TableCell>
              <TableCell>Country</TableCell>
              <TableCell align="left">Project name</TableCell>
              <TableCell align="left">Organization</TableCell>
              <TableCell align="left">Score(s)</TableCell>
              <TableCell align="left">Linked Team(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell align="left">1</TableCell>
              <TableCell component="th" scope="row" className={classes.bleu}>
              Uganda
              </TableCell>
              <TableCell align="left"><em>ADOS</em></TableCell>
              <TableCell align="left"><em>Gayaza High</em></TableCell>
              <TableCell align="left">141</TableCell>
              <TableCell align="left">UV - ROVER (137)</TableCell>
            </TableRow>
         
            <TableRow >
              <TableCell align="left">2</TableCell>
              <TableCell component="th" scope="row" className={classes.red}>
              Mali
              </TableCell>
              <TableCell align="left"><em>Kaalisi</em></TableCell>
              <TableCell align="left"><em>RobotsMali</em></TableCell>
              <TableCell align="left">136</TableCell>
              <TableCell align="left">LADƆNIYABAGA (132)</TableCell>
            </TableRow>
            <TableRow >
              <TableCell align="left">3</TableCell>
              <TableCell component="th" scope="row"className={classes.green}>
              Ghana
              </TableCell>
              <TableCell align="left"><em>IWCN</em></TableCell>
              <TableCell align="left"><em>SOS Hermann Gmeiner International College</em></TableCell>
              <TableCell align="left">132</TableCell>
              <TableCell align="left">Jobs for the future (119)<br/>Manna Tiles (107)</TableCell>
            </TableRow>
          
            <TableRow >
              <TableCell align="left">4</TableCell>
              <TableCell component="th" scope="row" className={classes.orange}>
              Nigeria
              </TableCell>
              <TableCell align="left"><em>Automated Construction Robot</em></TableCell>
              <TableCell align="left"><em>Graceland International School</em></TableCell>
              <TableCell align="left">110</TableCell>
              <TableCell align="left">Robot of the future (99)</TableCell>
            </TableRow>
        
            <TableRow>
              <TableCell align="left">5</TableCell>
              <TableCell component="th" scope="row">
              Angola
              </TableCell>
              <TableCell align="left"><em>Ending the drought</em></TableCell>
              <TableCell align="left"><em>Complexo Escolar Privado Internacional</em></TableCell>
              <TableCell align="left">105</TableCell>
            </TableRow>
           
          </TableBody>
        </Table>
      </TableContainer> 

      <Typography variant="h6" className={classes.text}><strong>Public Vote Results:</strong> Congratulations to UV-Rover for being the fan favorite and receiving the most number of likes on Facebook. 3 points was added to their final score. 2nd place was ADOS so 2 points added to their final score, and 3rd place was LADƆNIYABAGA so 1 point added to their final score.</Typography> 
      
    </div>
  );
}

Ranking.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  rankings: state.rankingData
});

const mapDispatchToProps = dispatch => {
  return {
    getProjectsPublic: () => dispatch(getProjectsPublic())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ranking));