import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const UseForm = (callback, validate, formValues) => {
  // console.log(formValues);
  const [values, setValues] = useState(formValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  // console.log(values);
  // Set input text value
  const handleChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  // Set checkbox
  const handleCheck = event => {
    const { name, checked } = event.target;
    setValues({ ...values, [name]: checked });
  };

  // Submit post
  const handleSubmit = event => {
    event.preventDefault();
    // Handling errors
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    //   check to see if there are errors
    if (Object.keys(errors).length === 0 && isSubmitting) {
      // validation successFull
      // call our callback
      callback();
      clear(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting]);

  // Clear form input by return initial value
  const clear = formValues => {
    // return the state to initial
    setValues(formValues);
  };

  return {
    handleChange,
    handleCheck,
    handleSubmit,
    values,
    errors
  };
};

UseForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setIsSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default UseForm;
