import {
    LOADING_TEAM,
    STOP_LOADING_TEAM,
    SET_TEAMS,
    SET_TEAM
  } from "../Types";

  const initialState = {
    teams: [],
    team: {},
    loading: false
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function(state = initialState, action) {
    // console.log(state);

    switch (action.type) {
      case LOADING_TEAM:
        return {
          ...state,
          loading: true
        };
      case STOP_LOADING_TEAM:
        return {
          ...state,
          loading: false
        };
      case SET_TEAMS:
        return {
          ...state,
          teams: action.payload,
          loading: false
        };
      case SET_TEAM:
        return {
          ...state,
          team: action.payload,
          loading: false
        };
      default:
        return state;
    }
  }
