import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// SETTING UP REDUX STORE
import { Provider, useSelector } from "react-redux";
import store from "./Redux/Store";
// ENHANCING STORE WITH FIREBASE
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "./config/fire";
// Components
import Loader from "./Utils/Loader";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users", // where profiles are stored in database
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  // enableClaims: true, // Get custom claims along with the profile
  // presence: "presence", // where list of online users is stored in database
  sessions: "sessions"
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};

// Wait For Auth To Load
function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div>
        <Loader />
      </div>
    );
  return children;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();