import React from 'react';
import PropTypes from "prop-types";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import store from "../../Redux/Store";
// Reusable component
import useForm from "../../Utils/UseForm";
import validate from "../../Utils/formValidations/validateSignInDetails";
// MUI Stuff
import { makeStyles, ThemeProvider, createMuiTheme, } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
// Components
import logo from '../layout/Main/logo.jpeg';
// Redux stuff
import { connect } from "react-redux";
import { signin } from "../../Redux/Actions/Auth";
import { LOGIN_REQUEST, SIGNIN_ERROR } from "../../Redux/Types";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0025FF",
  }},
});

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    maxWidth: '100%',
    margin: "0 auto"
  },
  logo: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: "0 auto"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "default",
  },
  formItems: {
    color: "black",
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#0025FF",
  },
  customError: {
    color: 'red'
  }
}));

function SignIn(props) {
  const { user:{loginRequest, userErrors, isAuthenticated} } = props;
  const classes = useStyles();
  const history = useHistory();
    // console.log(auth);
  // Set default form values
  let formValues = {
    username: "",
    password: ""
  };
  // call use form
  const { handleChange, handleSubmit, values, errors } = useForm(
    submit,
    validate,
    formValues
    );
    // Redirect if User login
    if (isAuthenticated) return <Redirect to="/" />;

    // Submit post
    function submit() {
      store.dispatch({ type: LOGIN_REQUEST });
      // fill data
      const userData = {
        username: values.username,
        password: values.password
      }
      // console.log(userData);
      // Connecting user
      axios.post(`&username=${userData.username}&password=${userData.password}&securityid=003F3AF428B24454B5EB2C6862AE8F65`).then(res => {
        // console.log(res.data);
        props.signin(res.data, () => history.push('/'));
      }).catch(err => {
        console.log(err.response.data);
        store.dispatch({
          type: SIGNIN_ERROR,
          payload: "Something went wrong, we couldn't reset your account. Please try again."
        });
      });
    }

  return (
    <div className={classes.root}>
        {/* <img src={logo} alt="PARC logo" className={classes.logo}/> */}
        <Avatar alt="Remy Sharp" src={logo}  className={classes.logo} />

        <Typography component="h1" variant="h5" align="center">
          Sign in with your PARC account details
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <ThemeProvider theme={theme}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Your username"
              name="username"
              autoComplete="username"
              autoFocus
              error={errors.username ? true : false}
              helperText={errors.username}
              value={values.username}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password ? true : false}
              helperText={errors.password}
              value={values.password}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="default" />}
              label="Remember me"
            />
            {userErrors && <Typography variant="body2" className={classes.customError}>{userErrors.error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              className={classes.submit}
              disabled={loginRequest}
            >
              Sign In
              {loginRequest && (
                    <CircularProgress
                      size={30}
                      className={classes.progressSpinner}
                    />
                  )}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2" className={classes.formItems}>
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="https://parcrobotics.org/my-profile?view=registration" variant="body2" className={classes.formItems}>
                  {"Don't have an account? Register now"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
            </Box>
          </ThemeProvider>
        </form>
    </div>
  );
}

SignIn.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    signin: (userData, callback) => dispatch(signin(userData, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);