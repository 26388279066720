export default function validateCreateProject(values) {
    let errors = {};
    if (!values.name) {
      errors.name = "required";
    }

    if (values.email) {
      if (values.email.indexOf("@") === -1) {
        errors.email = "Requires valid email";
      }
    }

    if (!values.teamname) {
      errors.teamname = "required";
    }

    if (!values.orgname) {
      errors.orgname = "required";
    }

    if (!values.videoLink) {
      errors.videoLink = "required";
    }

    if (!values.description) {
      errors.description = "required";
    } else if (values.description.length > 2000) {
      errors.description = "Description can not countain more than 2000 characters long";
    }

    return errors;
  }
