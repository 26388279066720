import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, ThemeProvider, createMuiTheme, } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0025FF",
  }},
});

const useStyles = makeStyles({
  cardStyle: {
    width: 500,
    height: 600,
    marginTop: 70,
  },
  root: {
    marginTop: 30,
    marginLeft: -100,
  },
  textStyle: {
    color: "#FF6400",
    fontWeight: "bold",
    }
});

const ProjectsList = props => {
  const classes = useStyles();

  const { history } = props;

  const handleButtonClick = (pageURL) => {
    history.push(pageURL);
  };
  return (
    <div>

    <Grid container spacing={5}>
      <Grid item xs={5}>
      <Typography className={classes.root} gutterBottom variant="h4" component="h2">
        How can we help?
        <Typography variant="body2" color="textSecondary" component="p">
        <p>
        The Pan African Robotics Competition (PARC) is a youth robotics competition by SenEcole, that engages STEM students across Africa and its diaspora. This platform has been created to facilitate the 2021 virtual Makers League challenge.
        </p>
        <p>
        Please contact us using the  details below or submit the form on the right if you have any questions about PARC or this platform.
        </p>
        </Typography>
        <Typography variant="body2" component="p" className={classes.textStyle}>
        Email:
        </Typography>
        <Typography variant="body2" component="p">
        <Link href="mailto:parcrobotics@senecole.com" variant="body2" className={classes.formItems}>
        parcrobotics@senecole.com
        </Link>
        </Typography>
        <Typography variant="body2" component="p" className={classes.textStyle}>
        Website:
        </Typography>
        <Typography variant="body2" component="p">
        <Link href="https://www.parcrobotics.org" variant="body2" className={classes.formItems}>
        www.parcrobotics.org
        </Link>
        </Typography>
      </Typography>

      </Grid>
      <Grid item xs={6} >

      <Card className={classes.cardStyle}>
        <CardContent>
          <Typography gutterBottom variant="h6"className={classes.textStyle}>
          Fill in the form below to contact us with any queries.
          </Typography>
          <ThemeProvider theme={theme}>
          <form noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="subject"
              label="Subject"
              name="subject"
              autoComplete="subject"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              rows={14}
              name="message"
              label="Message"
              type="message"
              id="message"
              autoComplete="message"
            />
            </form>
            </ThemeProvider>
        </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <Button size="large" variant="outlined" className={classes.textStyle} onClick={() => handleButtonClick("/")} >
          Send
        </Button>
      </CardActions>
    </Card>
      </Grid>
    </Grid>

    </div>

  )
};

export default withRouter(ProjectsList);
