// Types
import {
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNIN_ERROR,
    SET_USER_DETAILS,
    SIGNOUT_SUCCESS,
    RESET_SUCCESS,
    RESET_ERROR,
    LOGIN_REQUEST,
    CLEAR_ERRORS_USER
  } from "../Types";
  // Firebase
  import firebase from "../../config/fire";
  const db = firebase.firestore();

  // Signing up with Firebase
  export const signup = (newUser, callback) => {
    return (dispatch, getState) => {
      // make async call to database
      let socialProvider, userId;
      // default image
      dispatch({ type: LOGIN_REQUEST });

      const newDataUser = {
        email: newUser.email,
        password: newUser.password,
        confirmPassword: newUser.confirmPassword,
        fullname: newUser.fullname,
        country: newUser.country
      };
      // Launch signup
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          newDataUser.email,
          newDataUser.password
        )
        .then(dataBeforeEmail => {
          // get signup provider and userId
          socialProvider = dataBeforeEmail.user.providerData[0].providerId;
          userId = dataBeforeEmail.user.uid;

          // Fill data user
          const userCredentials = {
            email: newDataUser.email,
            fullname: newDataUser.fullname,
            country: newDataUser.country,
            createdAt: new Date().toISOString(),
            userId,
            role: "judge",
            provider: socialProvider
          };
          // Add user document
          return db
            .collection("users")
            .doc(userId)
            .set(userCredentials);
        })
        .then(() => {
          //   Email is verified
          dispatch({
            type: SIGNUP_SUCCESS,
            payload: "Your account was successfully created!"
          });
          dispatch({ type: CLEAR_ERRORS_USER });
          callback();
        })
        .catch(err => {
          // console.error(err);
          // auth/invalid-sender
          if (err.code === "auth/email-already-in-use") {
            dispatch({
              type: SIGNUP_ERROR,
              payload:
                "The email address is already in use by another account."
            });
          } else {
            dispatch({
              type: SIGNUP_ERROR,
              payload:
                "Something went wrong, we couldn't create your account. Please try again."
            });
          }
        });
    };
  };

  // Signing in with Firebase
export const signin = (userData, callback) => {
  return (dispatch, getState) => {
    // console.log(userData);
    // make async call to database
    dispatch({ type: LOGIN_REQUEST });
    if(userData.code === "200" && userData.result === "true") {
      // Get data ok and Signin success
      // console.log("user connected.");
      const userDetails = {
        email: userData.email,
        name: userData.name,
        role: userData.role,
        userId: userData.userid
      }
      // console.log(userDetails);
      // Local storage if
      localStorage.setItem('PARCUserData', JSON.stringify(userDetails));
      // console.log(userDetails);
      dispatch({
        type: SET_USER_DETAILS,
        payload: userDetails
      });
      dispatch({ type: CLEAR_ERRORS_USER });
      callback();
    } else if(userData.code === "404" && userData.result === "false") {
      // console.log("user does not exist!");
      dispatch({
        type: SIGNIN_ERROR,
        payload: "The username or password that you've entered is incorrect."
      });
    }else {
      // console.log("Something went wrong, we couldn't reset your account. Please try again");
      dispatch({
        type: SIGNIN_ERROR,
        payload: "Something went wrong, we couldn't reset your account. Please try again."
      });
    }
  };
};

// Signing out with Firebase
export const signout = () => {
  return (dispatch, getState) => {
    // make async call to database
    // console.log("logout success");
    localStorage.removeItem("PARCUserData"); //remove one item
    dispatch({ type: SIGNOUT_SUCCESS, payload: "You are loged out" });
  };
};

// Reset password with Firebase
export const resetPassword = email => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() =>
        dispatch({
          type: RESET_SUCCESS,
          payload: "Reset email sent. Go check your inbox."
        })
      )
      .catch(err => {
        dispatch({
          type: RESET_ERROR,
          payload:
            "Something went wrong, we couldn't reset your account. Please try again"
        });
      });
  };
};
