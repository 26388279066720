import React from "react";
import PropTypes from 'prop-types';
// MUI Stuff
import { makeStyles,ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
// Components
import en from './image/english-design-thinking.jpg';
import ws from './image/english-ws-lc.jpg';
import image from './image/makers-img.jpg';
import project from './image/project.jpg';
import judging from './image/judging.jpg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={4}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const theme = createMuiTheme({
    palette: {
    primary: {
        main: "#0025FF",
    },
    secondary: {
      main: "#ffffff",
  },
},
  });  

const useStyles = makeStyles((theme) => ({
    // root: {
    //   flexGrow: 1,
    //   backgroundColor: theme.palette.background.paper,
    // },
    root: {
        minWidth: '50%',
        maxWidth: '80%',
        margin: "0 auto"
    },
    button:{
      marginBottom: 10
    },
    tabs:{
      backgroundColor: "#ff6600",
      color: "#ffffff"
    }
  }));

const Challenge = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
      <Button color="primary" className={classes.button} variant="outlined" href={"https://firebasestorage.googleapis.com/v0/b/parcleagues.appspot.com/o/PARC%202021%20Makers(1).pdf?alt=media&token=d1329000-8e8f-45a3-8a6d-38a50d69e1c9"} target="_blank">
    Download the full challenge
   </Button>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Challenge" {...a11yProps(0)} />
          <Tab label="Deliverables" {...a11yProps(1)} />
          <Tab label="Thinking Methods" {...a11yProps(2)} />
          <Tab label="Judging and Rubric" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <img src={image} width='60%'/>
        <Typography paragraph>
        The work environment is steadily evolving over the past decades with new innovations and technology emerging around the globe. In the “African Youth Works” PARC challenge, Africa will not be left out of these advancements. Competing teams are tasked to assess opportunities for job creation in their respective countries bearing in mind pandemic and post pandemic needs. The contributions from this year’s MAKERS league will encourage employment for African youth and create sustainable pathways to wealth.
        </Typography>
        <Typography paragraph>
        Africa is the youngest and fastest-growing continent in the world, and it is estimated that by 2050 African youths will make up 33 percent of the world’s population. The COVID-19 pandemic has exasperated the problem, increasing the significant gap between the number of young people seeking work and the employment opportunities available to them. MAKERS teams are challenged to transform employment in Africa by using science and technology to design solutions in their communities that help increase job opportunities during a pandemic and/or the recovery of a pandemic.
        </Typography>

        <Typography paragraph>
        Teams may use the human-centered Design Thinking approach to design or build a new service or product. Alternatively, if teams choose to redesign or improve upon an existing product or system, they must use the Whole System & Life Cycle Thinking sustainable design approach. Teams who chose to program a device as part of their model have the option of using the MathWorks sponsored software for free. Teams must present a model of their innovation, and describe their team process as part of the judging process.
        </Typography>
        <img src={project} width='60%'/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <h3> Deliverables</h3>
      <Typography paragraph>
      Each team will be assigned an online booth in the PARCEXhibit platform. Teams will upload the following deliverables for judging:
    </Typography>

      <ul>
          <li>
          Upload a description of the team’s Design Thinking Process or Whole System & Life Cycle Thinking process
          </li>
          <li>
          Upload a model of the team’s innovation (example: model, flowchart, schematic, circuit diagram, etc)
          </li>
          <li>
          Upload a one-page summary of the team’s innovation
          </li>
          <li>
          Upload a 5-7 minute recorded presentation of innovation (in French or English)
          </li>
      </ul>
      <h3>Model of Innovation</h3>
      <Typography>
      Makers teams are tasked to create a model that describes their innovation. The model is an artistic representation of the team's idea. The model can be submitted as a jpeg, png or pdf. Models may be of a hardware, flowchart of a software, schematic, or circuit diagram of electronics - all depending on the team’s innovation. The model does not have to be on all aspects of the proposed solution but can be on specific sub-components. The model should show a great display of imagination, creativity, aesthetics and workmanship.
      <br/>The model has the following parameters that teams will need to follow:
      </Typography>
      <ul>
          <li>
          Model material cannot cost more than $200 USD. Teams must provide a material expense list. Teams must estimate the value of any scrap and donated materials used to ensure it does not exceed the $200 limit.
           </li>
          <li>
          Models have no required materials that must be used. Models can contain electronics and moving parts.
         </li>
          <li>
          Models cannot include any hazardous or illegal materials.
          </li>
          <li>
          Upload a 5-7 minute recorded presentation of innovation (in French or English)
          </li>
      </ul>
      <h3>One Page Summary</h3>
      <Typography>
      MAKERS teams must submit a one page summary in French or English introducing and describing their team innovation.
      </Typography>
      <i>Introduction</i>
      <ul>
          <li>
          Introduce team members
           </li>
          <li>
          State the country team is representing
         </li>
          <li>
          Title of the innovation
          </li>
          <li>
          In a few sentence summarize the innovation and projected impact
          </li>
          <li>
          Include a picture or diagram describing the innovation
          </li>
      </ul>
      <i>Innovation Description</i>
      <ul>
          <li>
          Describe your team’s innovation which may include answers to the common questions of “who”, “what”, “when”, “where” and “how”.
           </li>
          <li>
          Explain the problem or opportunity that the innovation will address to improve jobs during a pandemic and/or the recovery process post pandemic.
         </li>
          <li>
          Summarize the process your team used (either Design Thinking or Whole Systems & Life Cycle Thinking) to come up with your team innovation.
          </li>
          <li>
          Include descriptions of what makes the idea unique or innovative and the expected impact that will result from the innovation.
          </li>
      </ul>
      <h3>Presentation</h3>
      <Typography>
      Makers teams must deliver a 5-7 minutes presentation about their project. All team members are encouraged to have a speaking part during the presentation. Team members can use items such as their model or poster during the presentation. This is the team's moment to shine in front of hundreds of spectators and supporters... be creative and make the presentation exciting!
      </Typography>

      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography paragraph>
          <h3> Design Thinking</h3>
      Design Thinking is a structured problem-solving process that will allow Maker teams to find an effective and meaningful solution to increase employment opportunities in their communities. Teams must go through each phase of the Design Thinking Process (Empathy, Define, Ideate, Prototype, Test/Feedback) then create a summary portfolio.
     </Typography>
     <img src={en} width='60%'/>
     <Typography paragraph>
     <h3>Whole Systems and Life Cycle Thinking Option</h3>
     Teams may apply the sustainable thinking approach of Whole Systems & Life Cycle (WS+LC) to take an existing product or service and redesign it to have a better environmental impact while creating job opportunities for African youth.
     </Typography>
     <img src={ws} width='60%'/>
      </TabPanel>
      <TabPanel value={value} index={3}>
       <Typography>
       Teams will be judged on each of the following deliverables on a scale of 0-10:
       <ul>
         <li>
         Description of Design Thinking or Whole Systems & Life Cycle Thinking Process (content, aesthetic/organization)
         </li>
         <li>
         Model (idea, imagination, creativity, aesthetics, workmanship, within budget)
         </li>
         <li>
         One page summary of innovation (content, aesthetic/organization)
         </li>
         <li>
         Presentation (content, clarity, team work)
         </li>
       </ul>
       <Typography>
       Each deliverable will receive a score of (10)- Exemplary, (8)- Competent, (6)- Developing, (4)- Marginal, (2)-Needs Major Support.
The portfolio should include an expense sheet documenting all costs related to the model. Expenses may not exceed $200 USD. Teams should be prepared to answer questions about their portfolio from the judges. The team loses 1 point from their score for every dollar over $200.
<br/><b>The deadline to submit deliverables is June 13</b> <br/>
<img src={judging} width='60%'/>
         </Typography>

       </Typography>
      </TabPanel>
      </ThemeProvider>
    </div>
  );
}

export default Challenge;