// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import "firebase/messaging";
// Your app's Firebase configuration
let firebaseConfig;
// Change config in production build

firebaseConfig = {
  apiKey: "AIzaSyCisQdORJrFp9yzCEAAJBAjOiRAVC3Djxc",
  authDomain: "parcleagues.firebaseapp.com",
  projectId: "parcleagues",
  storageBucket: "parcleagues.appspot.com",
  messagingSenderId: "G-46S415F96Q",
  appId: "1:603120708520:web:f9986419ebf37872f90b9d"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Set Analytics firebase
// eslint-disable-next-line no-unused-vars
const analytics = firebase.analytics();
// Set Storage firebase
const storage = firebase.storage();

// export const firebaseApp = firebase.initializeApp(firebaseConfig);
// const baseDb = firebaseApp.firestore();
// export const db = baseDb;
// export const messaging

const messaging = firebase.messaging();
const realtimeStorage = firebase.database();

// const funcs =

// Finally, export it to use it throughout your app
export { messaging, storage, firebase as default, realtimeStorage };