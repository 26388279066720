// User reducer types
export const SET_USER = "SET_USER";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_ERRORS_USER = "CLEAR_ERRORS_USER";
export const SET_ERRORS_USER = "SET_ERRORS_USER";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const STOP_LOGIN_REQUEST = "STOP_LOGIN_REQUEST";
// *AUTH*
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
// *REGISTER*
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
// *SIGN IN*
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
// *SIGN OUT*
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "SIGNOUT_ERROR";
// **SIGN UP**
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
// *RESET PASSWORD*
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
// *UI reducer types*
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
// Toast reducers type
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
//*PROJECT*
export const LOADING_PROJECT = "LOADING_PROJECT";
export const STOP_LOADING_PROJECT = "STOP_LOADING_PROJECT";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECT = "SET_PROJECT";
//Team
export const LOADING_TEAM = "LOADING_TEAM";
export const STOP_LOADING_TEAM = "STOP_LOADING_TEAM";
export const SET_TEAMS = "SET_TEAMS";
export const SET_TEAM = "SET_TEAM";
//*HELP*
export const SEND_MESSAGE = "SEND_MESSAGE";
//*JUDGE*
export const SUBMIT_SCORES = "SUBMIT_SCORES";
export const DOWNLOAD_RUBRIC = "DOWNLOAD_RUBRIC";
export const LOADING_RANKING = "LOADING_RANKING";
export const STOP_LOADING_RANKING = "STOP_LOADING_RANKING";
export const SET_RANKINGS = "SET_RANKINGS";
export const SET_RANKING = "SET_RANKING";
export const SET_PROJECTS_RANKING = "SET_PROJECTS_RANKING";