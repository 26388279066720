import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import clsx from 'clsx';
// MUI Stuff
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
// Icons
import AppsIcon from '@material-ui/icons/Apps';
import GroupIcon from '@material-ui/icons/Group';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DescriptionIcon from '@material-ui/icons/Description';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
// import ScoreIcon from '@material-ui/icons/Score';
// Components
import Header from './Header';
// import SideMenu from './SideMenu';
// import Drawer from './SideMenu';
import logo from './logo.jpeg';
// Redux stuff
import { connect } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: "100%"
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logo: {
    maxWidth: 100,
    marginTop:"-52px",
    position: 'absolute',
    right: 0,
    maxHeight: 84
  },
  floating: {
    color: "#0025FF",
    background: "#F9FBFD",
    marginTop: 50,
    marginBottom: 10,
    marginRight: 15,
    marginLeft: 15,
    fontSize: "0.7rem",
  },
  children: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

const MainLayout = props => {
    const {children, history, user} = props;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const itemsList = [
      {
        text: "Challenge",
        icon: <DescriptionIcon />,
        onClick: () => history.push("/")
      },
      {
        text: "Challenge (français)",
        icon: <DescriptionIcon />,
        onClick: () => history.push("/french")
      },
      
      {
        text: "Team",
        icon: <GroupIcon />,
        onClick: () => history.push("/team")
      },
      {
        text: "Projects",
        icon: <AppsIcon />,
        onClick: () => history.push("/projects")
      },
      {
        text: "Rankings",
        icon: <FormatListNumberedIcon />,
        onClick: () => history.push("/rankings")
      },
      {
        text: "Mathworks",
        icon: <ImportContactsIcon />, 
        target:"_blank",
        onClick: () => history.push("/matworks")
      },
      // {
      //   text: "Judge",
      //   icon: <DescriptionIcon />,
      //   onClick: () => history.push("/judge")
      // }

    ];
    // console.log(user);

    const handleButtonClick = (pageURL) => {
      history.push(pageURL);
    };

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
      <div className={classes.root}>

      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{backgroundColor: "white", color: "#FF6600", boxShadow: "0px 0px 0px 1px"}}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
          {user.isAuthenticated ? <Header className={classes.account}/> : null}
          <img src={logo} alt="PARC logo" className={classes.logo} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <Fab size="small" aria-label = 'upload a project' variant='extended' className={classes.floating} onClick={() => handleButtonClick("/upload")} >
        <AddCircleRoundedIcon/>
        Upload a project
        </Fab>
        <List>
        {itemsList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem button key={text} onClick={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.children}> {children} </div>

      </main>

    </div>
    );
  };

MainLayout.propTypes = {
  children: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default compose(withRouter, connect(mapStateToProps))(MainLayout);
