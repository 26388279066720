import React from "react";
import PropTypes from 'prop-types';
// MUI Stuff
import { makeStyles,ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
// Components
import en from './image/english-design-thinking.jpg';
import ws from './image/english-ws-lc.jpg';
import image from './image/makers-img.jpg';
import project from './image/project.jpg';
import judging from './image/judging.jpg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={4}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const theme = createMuiTheme({
    palette: {
    primary: {
        main: "#0025FF",
    },
    secondary: {
      main: "#ffffff",
  },
},
  });  

const useStyles = makeStyles((theme) => ({
    // root: {
    //   flexGrow: 1,
    //   backgroundColor: theme.palette.background.paper,
    // },
    root: {
        minWidth: '50%',
        maxWidth: '80%',
        margin: "0 auto"
    },
    button:{
      marginBottom: 10
    },
    tabs:{
      backgroundColor: "#ff6600",
      color: "#ffffff"
    }
  }));

const Challenge_fr = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
      <Button color="primary" className={classes.button} variant="outlined" href={"https://firebasestorage.googleapis.com/v0/b/parcleagues.appspot.com/o/PARC%202021%20Makers(1).pdf?alt=media&token=d1329000-8e8f-45a3-8a6d-38a50d69e1c9"} target="_blank">
      Téléchargez le défi complet 
   </Button>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="CHALLENGE" {...a11yProps(0)} />
          <Tab label="LIVRABLES" {...a11yProps(1)} />
          <Tab label="METHODE DE REFLEXION" {...a11yProps(2)} />
          <Tab label="JUGÉMENT" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <img src={image} width='60%'/>
        <Typography paragraph>
        L'environnement de travail évolue constamment
au cours des dernières décennies avec de
nouvelles innovations et technologies émergent
dans le monde entier. Dans le défi «African Youth
Works», l'Afrique ne sera pas en reste. Les
équipes concurrentes sont chargées d'évaluer les
possibilités de création d'emplois dans leurs
pays respectifs en gardant à l'esprit les besoins
en cas de pandémie et d'après-pandémie. Les
contributions de la ligue MAKERS de cette année
encouragent l’emploi des jeunes africains et
créent des voies durables vers la richesse.
        </Typography>
        <Typography paragraph>
        L’Afrique est le continent le plus jeune et à la croissance la plus
rapide au monde, et on estime que d’ici 2050, les jeunes Africains
représenteront 33% de la population mondiale. La pandémie
COVID-19 a exaspéré le problème, creusant l'écart important
entre le nombre de jeunes à la recherche d'un emploi et les
opportunités d'emploi qui s'offrent à eux. Les équipes MAKERS
sont mises au défi de transformer l'emploi en Afrique en utilisant
la science et la technologie pour concevoir des solutions dans
leurs communautés qui contribuent à augmenter les opportunités
d'emploi pendant une pandémie et / ou la reprise d'une
pandémie.
        </Typography>

        <Typography paragraph>
        Les équipes peuvent utiliser l'approche Design Thinking centrée
sur l'humain pour concevoir ou créer un nouveau service ou
produit. Sinon, si les équipes choisissent de concevoir ou
d'améliorer un produit ou un système existant, elles doivent
utiliser l'approche de conception durable Whole System & Life
Cycle Thinking. Les équipes qui ont choisi de programmer un
appareil dans le cadre de leur modèle ont la possibilité
d'utiliser gratuitement le logiciel sponsorisé par MathWorks.
Les équipes doivent présenter un modèle de leur
innovation et décrire leur processus d'équipe dans
le cadre du processus de jugement.
        </Typography>
        <img src={project} width='60%'/>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <h3> Deliverables</h3>
      <Typography paragraph>
      Chaque équipe se verra attribuer un stand en ligne
sur la plateforme PARCEXhibit. Les équipes
téléchargeront les livrables suivants pour
évaluation:
       </Typography>

      <ul>
          <li>
          Télécharger une description du processus de
conception de l’équipe ou du processus de
réflexion sur le système complet et le cycle de
vie
          </li>
          <li>
          Télécharger un modèle de l’innovation de
l’équipe (exemple: modèle, organigramme,
schéma, schéma de circuit, etc.)
          </li>
          <li>
          Téléchargez un résumé d'une page de
l'innovation de l'équipe
          </li>
          <li>
          Télécharger une présentation enregistrée
de l'innovation de 5 à 7 minutes
(en français ou en anglais)
          </li>
      </ul>
      <h3>MODÈLE D'INNOVATION</h3>
      <Typography>
      Les équipes de Makers sont chargées de créer un modèle qui
décrit leur innovation. Le modèle est une représentation
artistique de l'idée de l'équipe. Le modèle peut être soumis au
format jpeg, png ou pdf. Les modèles peuvent être un matériel,
un organigramme d'un logiciel, un schéma ou un schéma de
circuit électronique - tout dépend de l'innovation de l'équipe. Le
modèle ne doit pas nécessairement porter sur tous les aspects
de la solution proposée, mais peut être sur des sous-
composants spécifiques. Le modèle doit montrer une grande
démonstration d'imagination, de créativité, d'esthétique et de
fabrication.
      <br/>Le modèle comporte les paramètres suivants que les équipes
devront suivre:
      </Typography>
      <ul>
          <li>
          Le matériau du modèle ne peut pas coûter plus de 200 USD.
Les équipes doivent fournir une liste des dépenses
matérielles. Les équipes doivent estimer la valeur de toute
ferraille et de tout matériel donné utilisé pour s'assurer
qu'elle ne dépasse pas la limite de 200 $.
           </li>
          <li>
          Les modèles n'ont aucun matériel requis qui doit être
utilisé. Les modèles peuvent contenir des composants
électroniques et mobiles.
          </li>
          <li>
          Les modèles ne peuvent pas inclure de matériaux
dangereux ou illégaux.
          </li>
          {/* <li>
          Upload a 5-7 minute recorded presentation of innovation (in French or English)
          </li> */}
      </ul>
      <h3>RÉSUMÉ D'UNE PAGE</h3>
      <Typography>
      Les équipes MAKERS doivent soumettre un résumé d'une
page en français ou en anglais présentant et décrivant
l'innovation de leur équipe.
      </Typography>
      <i>Introduction</i>
      <ul>
          <li>
          Présentez les membres de l'équipe
           </li>
          <li>
          Indiquez que l'équipe de pays représente
         </li>
          <li>
          Titre de l'innovation
          </li>
          <li>
          En quelques phrases résumez l'innovation et l'impact projeté
          </li>
          <li>
          Inclure une image ou un diagramme décrivant l'innovation
          </li>
      </ul>
      <i>Description de l'innovation</i>
      <ul>
          <li>
          Décrivez les innovations de votre équipe qui peuvent
inclure des réponses aux questions courantes de «qui»,
«quoi», «quand», «où» et «comment».
           </li>
          <li>
          Expliquez le problème ou l'opportunité que l'innovation
abordera pour améliorer les emplois pendant une
pandémie et / ou le processus de rétablissement après la
pandémie.
           </li>
          <li>
          Résumez le processus utilisé par votre équipe (soit
Design Thinking ou Whole Systems & Life Cycle Thinking)
pour proposer l'innovation de votre équipe.
          </li>
          <li>
          Incluez des descriptions de ce qui rend l'idée unique ou
innovante et de l'impact attendu qu'il résulte de
l'innovation.
           </li>
      </ul>
      <h3>Presentation</h3>
      <Typography>
      Les équipes de Makers doivent faire une
présentation de 5 à 7 minutes sur leur projet.
Tous les membres de l'équipe sont
encouragés à prendre la parole pendant la
présentation. Les membres de l'équipe
peuvent utiliser des éléments tels que leur
modèle ou leur affichage pendant la
présentation. C'est le moment pour l'équipe
de briller devant des centaines de
spectateurs et de supporters ... soyez créatif
et rendez la présentation passionnante!
      </Typography>

      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography paragraph>
          <h3> Option de Design Thinking</h3>
          Le Design Thinking est un processus
structuré de résolution de problèmes
qui permettra aux équipes Maker de
trouver une solution efficace et
significative pour augmenter les
opportunités d'emploi dans leurs
communautés. Les équipes doivent
passer par chaque phase du processus
de Design Thinking (Empathie, Définir,
Idée, Prototype, Test / Feedback) puis
créer un portfolio récapitulatif.
       </Typography>
     {/* <img src={en} width='60%'/> */}
     <Typography paragraph>
     <h3>Option de réflexion sur les systèmes entiers et le cycle de vie</h3>
     Les équipes peuvent appliquer l'approche de pensée durable de Whole Systems & Life Cycle (WS + LC) pour prendre un produit ou service existant et le redéfinir pour avoir un meilleur impact environnemental tout en créant des opportunités d'emploi pour la jeunesse africaine.
     </Typography>
     {/* <img src={ws} width='60%'/> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
       <Typography>
       Les équipes seront jugées sur chacun des livrables suivants sur une échelle de 0 à 10:
       <ul>
         <li>
         Description de la pensée de conception ou du processus de réflexion sur les systèmes entiers et le cycle de vie (contenu, esthétique / organisation)
         </li>
         <li>
         Modèle (idée, imagination, créativité, esthétique, exécution, dans les limites du budget)
         </li>
         <li>
         Résumé d'une page de l'innovation (contenu, esthétique / organisation)
         </li>
         <li>
         Présentation (contenu, clarté, travail d'équipe)
         </li>
       </ul>
       <Typography>
       Chaque livrable recevra une note de (10) - Exemplaire, (8) - Compétent, (6) - En développement, (4) - Marginal, (2) -Nécessite un soutien majeur.
Le portefeuille doit inclure une feuille de dépenses documentant tous les coûts liés au modèle. Les dépenses ne peuvent excéder 200 USD. Les équipes doivent être prêtes à répondre aux questions des juges sur leur portfolio. L'équipe perd 1 point de son score pour chaque dollar supérieur à 200 $.<br/><b>The deadline to submit deliverables is June 13</b> <br/>
<img src={judging} width='60%'/>
         </Typography>

       </Typography>
      </TabPanel>
      </ThemeProvider>
    </div>
  );
}

export default Challenge_fr;