import {
    LOADING_PROJECT,
    STOP_LOADING_PROJECT,
    CLEAR_ERRORS,
    SET_PROJECTS,
    SET_PROJECT,
  } from "../Types";
  // import axios from "axios";
  import { showSuccessSnackbar } from "./Snackbar";
  // Firebase
  import firebase from "../../config/fire";
  const db = firebase.firestore();

  /**
   * TODO: ALL PROJECTS ACTIONS
   **/
  //create Project
  export const createProject = (newProject, historyCallback) => {
    return (dispatch, getState) => {
      // Add data
      const newDataProject = {
        name: newProject.name,
        teamId: newProject.teamId,
        teamname: newProject.teamname,
        description: newProject.description,
        email: newProject.email,
        orgname: newProject.orgname,
        videoLink: newProject.videoLink,
        summary: newProject.summary,
        presentation: newProject.presentation,
        model: newProject.model,
        userId: newProject.userId,
        createdAt: new Date().toISOString(),
        softDelete: false
      };
      // console.log(newDataProject);

      if (newProject.userId) {
        dispatch({ type: LOADING_PROJECT });
        db.collection("projects")
          .add(newDataProject)
          .then(doc => {
            const resProject = newDataProject;
            resProject.projectId = doc.id;
            return resProject;
          })
          .then((project) => {
            dispatch({ type: STOP_LOADING_PROJECT });
            dispatch(showSuccessSnackbar("Project added successfully, last one add the presentation"));
            historyCallback.push(`/project/details/${project.projectId}`);
          })
          .catch(err => {
            dispatch({ type: STOP_LOADING_PROJECT });
            dispatch(showSuccessSnackbar("Project added error, please try again !"));
          });
      } else {
        // console.log("user not login");
        dispatch({ type: STOP_LOADING_PROJECT });
        dispatch(
          showSuccessSnackbar(
            "Try again, something went wrong data user can not be load..."
          )
        );
      }
    };
  };

  // Edit Project details
  export const updateProjects = (projectDetails, projectId) => {
    return (dispatch, getState) => {
      // console.log(projectDetails);
      // Update project
      db.collection("projects")
        .doc(projectId)
        .update(projectDetails)
        .then(() => {
          // dispatch(showSuccessSnackbar("Project updated successfully !"));
          return true
        })
        .catch(err => {
          // console.error(err);
          dispatch(showSuccessSnackbar("Project updated error, please try again !"));
        });
    };
  };

// Get all Projects
export const getProjects = () => {
    return (dispatch, getState) => {
      // make async call to database
      dispatch({ type: LOADING_PROJECT });
      try {
        db.collection("projects")
          .orderBy("createdAt", "desc")
          .where("softDelete", "==", false)
          .onSnapshot(data => {
            let projects = [];
            data.forEach(doc => {
              projects.push({
                projectId: doc.id ? doc.id : null,
                name: doc.data().name ? doc.data().name : null,
                teamId: doc.data().teamId ? doc.data().teamId : null,
                teamname: doc.data().teamname ? doc.data().teamname : null,
                picture: doc.data().picture ? doc.data().picture : null,
                description: doc.data().description ? doc.data().description : null,
                email: doc.data().email ? doc.data().email : null,
                orgname: doc.data().orgname ? doc.data().orgname : null,
                videoLink: doc.data().videoLink ? doc.data().videoLink : null,
                summary: doc.data().summary ? doc.data().summary : null,
                presentation: doc.data().presentation ? doc.data().presentation : null,
                model: doc.data().model ? doc.data().model : null,
                comment: doc.data().comment ? doc.data().comment : null,
                userId: doc.data().userId ? doc.data().userId : null,
                createdAt: doc.data().createdAt ? doc.data().createdAt : null
              });

              return projects;
            });
            dispatch({ type: SET_PROJECTS, payload: projects });
          });
      } catch (error) {}
    };
  };

// Get specific Project
// export const getProject = projectId => {
//     return (dispatch, getState) => {
//       // make async call to database
//       let projectData = {};
//       const docRef = db.collection("projects").doc(projectId);

//       dispatch({ type: LOADING_PROJECT });

//       docRef.get()
//         .then(doc => {
//         // if (!doc.exists) {
//         //   console.log("File do not exist !");
//         // }
//         projectData = doc.data();
//         projectData.projectId = doc.id;
//         // collect data
//         dispatch({ type: SET_PROJECT, payload: projectData });
//         dispatch({ type: STOP_LOADING_PROJECT });
//       })
//       .catch((error) => {
//         // console.log("Error getting document:", error);
//         dispatch(showSuccessSnackbar("Error get project !"));
//       });

//     };
// };

// Realtime
export const getProject = projectId => {
  return (dispatch, getState) => {
    // make async call to database
    let projectData = {};
    dispatch({ type: LOADING_PROJECT });

    try {
      db.collection("projects")
        .doc(projectId)
        .onSnapshot(doc => {
          // if (!doc.exists) {
          //   console.log("File do not exist !");
          // }
          projectData = doc.data();
          projectData.projectId = doc.id;
          // collect data
          dispatch({ type: SET_PROJECT, payload: projectData });
          dispatch({ type: STOP_LOADING_PROJECT });
        });
    } catch (error) {
      // console.log("Error getting document:", error);
      dispatch(showSuccessSnackbar("Error get project !"));
    }
  };
};

  // Delete Project
  export const deleteProject = projectId => {
    return (dispatch, getState) => {
      // make async call to database
      // const firebaseData = getState().firebase;
      // Get current document
      const projectDocument = db.collection("projects").doc(projectId);

      projectDocument
        .update({
          softDelete: true
        })
        .then(() => {
          dispatch(showSuccessSnackbar("Project deleted successfully"));
        })
        .catch(err => {
          // console.error(err);
          dispatch(
            showSuccessSnackbar("Project delete error, please try again !")
          );
        });
    };
  };

  /**
   * TODO: GENERAL ACTIONS
   **/
  // Clear error
  export const clearErrors = () => dispatch => {
    dispatch({ type: CLEAR_ERRORS });
  };
