import React from "react";
// MUI Stuff
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    textAlign: "center"
  },
  textLoading: {
    marginTop: "15%"
  }
}));

function Loader(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.content}>
          <Typography className={classes.textLoading} variant="caption" display="block" gutterBottom>
            League dashboard loading...
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Loader;
