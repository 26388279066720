import React from 'react'
// import PropTypes from 'prop-types'

export const Image = (props) => {
    const {
        alt,
        ...otherProps
      } = props;

    return <img alt={alt} {...otherProps} />
}

Image.propTypes = {}

export default Image
