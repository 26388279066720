import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
// Firebase config
import { storage } from "../../config/fire";
// Reusable component
import useForm from "../../Utils/UseForm";
import validate from "../../Utils/formValidations/validateCreateTeam";
// MUI Stuff
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// import ArrowLeft from "@material-ui/icons/ArrowLeft";
// Redux stuff
import { connect } from "react-redux";
import { getTeams, createTeam } from "../../Redux/Actions/Team";
import { showSuccessSnackbar } from "../../Redux/Actions/Snackbar";
// Component
import icon from './../image/undraw_team_spirit_hrr4.svg';
import Image from "../../Utils/Image";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
content:{
  display: 'block',
  marginRight: 'auto'
},
textField:{
  marginBottom: 15
},
noproject:{
  margin: "0 auto",
  textAlign: "center"
},
button:{
  float: "right"
}
});

function Team (props) {
  const classes = useStyles();
  const { user, data:{loading, teams} } = props;
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  // console.log(user);

  // ComponentDidMount Hoock
  useEffect(() => {
    props.getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let formValues = {
    teamname: "",
    girls: "",
    country: "",
    members: "",
    coachs: "",
    picture: ""
  };
  // call use form
  const { handleChange, handleSubmit, values, errors } = useForm(
    submit,
    validate,
    formValues
    );
    // Redirect if User login
    // if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // Handle Event Image
  const handleImageChange = event => {
    const image = event.target.files[0];
    if (image) {
      // console.log(image);
      // Get file type
      const fileType = image["type"];
      // Get file size
      const img = image.size;
      const fileSize = img / 102400;
      // console.log(fileSize);
      // Valid type image accepted
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/jpg",
        "image/png"
      ];

      // Verify and download
      if (validImageTypes.includes(fileType)) {
        if (fileSize > 102400) {
          //   setError("Your image is too large. Max size is 1 megabyte");
          props.showSuccessSnackbar(
            "Your image is too large. Max size is 100 megabyte"
          );
        } else {
          setImage(image);
          // console.log(image.name);
          // Load file reader
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              // console.log(reader.result);
              setUrl(reader.result);
            },
            false
          );
          reader.readAsDataURL(image);
        }
      } else {
        props.showSuccessSnackbar("Please select an image to upload");
      }
    }
  };
  // Edit image article
  const handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

   // Submit form
   function submit() {
    // Verify if image selected
    if (image) {
      // Upload image
      const uploadTask = storage.ref(`teams/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          //   setError(error);
          props.showSuccessSnackbar(error);
        },
        () => {
          // complete function ...
          storage
            .ref("teams")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
              setUrl(url);
              setProgress(0);
              const newTeam = {
                teamname: values.teamname,
                girls: values.girls,
                country: values.country,
                members: values.members,
                coachs: values.coachs,
                userId: user.credentials.userId,
                picture: url
              };
              // console.log(newTeam);
              props.createTeam(newTeam);
              setUrl("");
              setImage(null);
              handleClose();
            });
        }
      );
    } else {
      props.showSuccessSnackbar("Please select an image for the article");
      handleClose();
    }
  }

  // List markup
  const markupteamsList = teams.length !== 0 ? teams.map(team =>
  <Grid item md={3} xs={12} key={team.teamId}>
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image= {team.picture}
          title={team.teamname}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {team.teamname}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Country: {team.country} <br/>
            All the members: {team.members}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary" onClick={() => handleButtonClick("/team") }>
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  </Grid>) :<div className={classes.noproject}>  <Image src={icon} width="50%"/> <Typography>  No Team found yet</Typography></div>;

  // Button markup
  const buttonMarkup = user.isAuthenticated ? <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.button}>
  Add Your Team </Button> : <Button variant="outlined" color="primary" component={Link} to="/signin" className={classes.button}>Add Your Team</Button>;

  return (
    <div className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12}>

          {buttonMarkup}
        </Grid>
        {markupteamsList}
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Team info </DialogTitle>
        <DialogContent>
          <TextField
            name="teamname"
            autoFocus
            margin="dense"
            id="teamname"
            label="Team Name"
            type="text"
            className={classes.textField}
            error={errors.teamname ? true : false}
            helperText={errors.teamname}
            value={values.teamname}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="country"
            autoFocus
            margin="dense"
            id="country"
            label="Country"
            type="text"
            className={classes.textField}
            error={errors.country ? true : false}
            helperText={errors.country}
            value={values.country}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="girls"
            margin="dense"
            id="girls"
            label="Number of girls"
            placeholder="5"
            type="text"
            className={classes.textField}
            error={errors.girls ? true : false}
            helperText={errors.girls}
            value={values.girls}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="members"
            type="text"
            label="Members"
            placeholder="Member"
            required
            rows={4}
            variant="filled"
            className={classes.textField}
            error={errors.members ? true : false}
            helperText={errors.members}
            value={values.members}
            onChange={handleChange}
            fullWidth
            multiline
          />

          <TextField
            name="coachs"
            type="text"
            label="Coachs"
            placeholder="Coach"
            required
            rows={4}
            variant="filled"
            className={classes.textField}
            error={errors.coachs ? true : false}
            helperText={errors.coachs}
            value={values.coachs}
            onChange={handleChange}
            fullWidth
            multiline
          />

          <input
              type="file"
              id="imageInput"
              hidden="hidden"
              onChange={handleImageChange}
            />
            <LinearProgress variant="determinate" value={progress} />
            <Button
            variant="contained"
            color="default"
            className={classes.buttonStyle}
            required
            startIcon={!url ? <CloudUploadIcon /> : <AttachFileIcon/>}
            onClick={handleEditPicture}
            fullWidth
            // disabled={url}
            >
              {!url ? "Upload team picture" : "A selected image, click to change"}
            </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Submit
            {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  )
};

Team.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  data: state.teamData
});

const mapDispatchToProps = dispatch => {
  return {
    getTeams: () => dispatch(getTeams()),
    createTeam: (newTeam) => dispatch(createTeam(newTeam)),
    showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Team));
