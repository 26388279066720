import {
    LOADING_RANKING,
    STOP_LOADING_RANKING,
    CLEAR_ERRORS,
    SET_RANKINGS,
    SET_RANKING,
    SET_PROJECTS_RANKING
  } from "../Types";
  // import axios from "axios";
  import { showSuccessSnackbar } from "./Snackbar";
  // Firebase
  import firebase from "../../config/fire";
  const db = firebase.firestore();

  /**
   * TODO: ALL RANKINGS ACTIONS
   **/
  //create Project
  export const createRanking = (newRanking) => {
    return (dispatch, getState) => {
      // Add data
      const newDataRanking = {
        projectId: newRanking.projectId,
        videoContent: Number(newRanking.videoContent),
        clarity: Number(newRanking.clarity),
        teamwork: Number(newRanking.teamwork),
        contentOnePageSummary: Number(newRanking.contentOnePageSummary),
        aestheticOnePageSummary: Number(newRanking.aestheticOnePageSummary),
        organization: Number(newRanking.organization),
        empathize: Number(newRanking.empathize),
        define: Number(newRanking.define),
        ideate: Number(newRanking.ideate),
        model: Number(newRanking.model),
        proDefinition: Number(newRanking.proDefinition),
        lifPriorities: Number(newRanking.lifPriorities),
        comment: newRanking.comment,
        whoSolution: Number(newRanking.whoSolution),
        refSolution: Number(newRanking.refSolution),
        userId: newRanking.userId,
        createdAt: new Date().toISOString(),
        softDelete: false
      };

      // Total sum note judge
      const totalSum = newDataRanking.videoContent+
      newDataRanking.clarity+
      newDataRanking.teamwork+
      newDataRanking.contentOnePageSummary+
      newDataRanking.aestheticOnePageSummary+
      newDataRanking.organization+
      newDataRanking.empathize+
      newDataRanking.define+
      newDataRanking.ideate+
      newDataRanking.model+
      newDataRanking.proDefinition+
      newDataRanking.lifPriorities+
      newDataRanking.whoSolution+
      newDataRanking.refSolution;
      // console.log(totalSum);

      // Get current document projects
      const projectDocument = db.collection("projects").doc(newDataRanking.projectId);
      let projectData;

      if (newRanking.userId) {
        dispatch({ type: LOADING_RANKING });
        db.collection("rankings")
          .add(newDataRanking)
          .then(async () => {
            // Save score projects
            try {
              const doc = await projectDocument
                .get();
              if (doc.exists) {
                projectData = doc.data();
                projectData.projectId = doc.id;
              } else {
                dispatch(showSuccessSnackbar("Project not found"));
              }
              // Get total note and number of people who note
              projectData.noteCount++;
              const totalNote = projectData.score + totalSum;

              dispatch({ type: STOP_LOADING_RANKING });
              dispatch(showSuccessSnackbar("Score added successfully"));
              return await projectDocument.update({
                noteCount: projectData.noteCount,
                score: totalNote
              });
            } catch (err) {
              console.error(err);
            }
          })
          .catch(err => {
            dispatch({ type: STOP_LOADING_RANKING });
            dispatch(showSuccessSnackbar("Score added error, please try again !"));
          });
      } else {
        // console.log("user not login");
        dispatch({ type: STOP_LOADING_RANKING });
        dispatch(
          showSuccessSnackbar(
            "Try again, something went wrong data user can not be load..."
          )
        );
      }
    };
  };

  // Edit Ranking details
  export const updateRankings = (rankingDetails, rankingId) => {
    return (dispatch, getState) => {
      // console.log(rankingDetails);
      // Update ranking
      db.collection("rankings")
        .doc(rankingId)
        .update(rankingDetails)
        .then(() => {
          dispatch(showSuccessSnackbar("Score updated successfully !"));
        })
        .catch(err => {
          // console.error(err);
          dispatch(showSuccessSnackbar("Score updated error, please try again !"));
        });
    };
  };

// Get all Rankings
export const getRankings = () => {
    return (dispatch, getState) => {
      // make async call to database
      dispatch({ type: LOADING_RANKING });
      try {
        db.collection("rankings")
          .orderBy("createdAt", "desc")
          .where("softDelete", "==", false)
          .onSnapshot(data => {
            let rankings = [];
            data.forEach(doc => {
              rankings.push({
                rankingId: doc.id ? doc.id : null,
                projectId: doc.data().projectId ? doc.data().projectId : null,
                video: doc.data().video ? doc.data().video : null,
                onePageSummary: doc.data().onePageSummary ? doc.data().onePageSummary : null,
                empathize: doc.data().empathize ? doc.data().empathize : null,
                define: doc.data().define ? doc.data().define : null,
                ideate: doc.data().ideate ? doc.data().ideate : null,
                model: doc.data().model ? doc.data().model : null,
                proDefinition: doc.data().proDefinition ? doc.data().proDefinition : null,
                lifPriorities: doc.data().lifPriorities ? doc.data().lifPriorities : null,
                comment: doc.data().comment ? doc.data().comment : null,
                whoSolution: doc.data().whoSolution ? doc.data().whoSolution : null,
                refSolution: doc.data().refSolution ? doc.data().refSolution : null,
                userId: doc.data().userId ? doc.data().userId : null,
                createdAt: doc.data().createdAt ? doc.data().createdAt : null
              });

              return rankings;
            });
            dispatch({ type: SET_RANKINGS, payload: rankings });
          });
      } catch (error) {}
    };
  };

// Get all Projects
export const getProjects = () => {
  return (dispatch, getState) => {
    // make async call to database
    dispatch({ type: LOADING_RANKING });
    try {
      db.collection("projects")
        .orderBy("score", "desc")
        .where("softDelete", "==", false)
        .onSnapshot(data => {
          let projects = [];
          data.forEach(doc => {
            projects.push({
              projectId: doc.id ? doc.id : null,
              name: doc.data().name ? doc.data().name : null,
              teamId: doc.data().teamId ? doc.data().teamId : null,
              teamname: doc.data().teamname ? doc.data().teamname : null,
              score: doc.data().score ? doc.data().score : 0,
              noteCount: doc.data().noteCount ? doc.data().noteCount : 0,
              score: doc.data().score ? doc.data().score : null,
                videoscore: doc.data().videoscore ? doc.data().videoscore : null,
                summaryscore: doc.data().summaryscore ? doc.data().summaryscore : null,
                dl: doc.data().dl ? doc.data().dl : null,
                judge: doc.data().judge ? doc.data().judge : null,
                country: doc.data().country ? doc.data().country : null,
                comment: doc.data().comment ? doc.data().comment : null,
              createdAt: doc.data().createdAt ? doc.data().createdAt : null
            });

            return projects;
          });
          dispatch({ type: SET_PROJECTS_RANKING, payload: projects });
        });
    } catch (error) {}
  };
};

// Get all Projects for public
export const getProjectsPublic = () => {
  return (dispatch, getState) => {
    // make async call to database
    dispatch({ type: LOADING_RANKING });
    try {
      db.collection("projects")
        .orderBy("score", "desc")
        .where("softDelete", "==", false)
        .limit(5)
        .onSnapshot(data => {
          let projects = [];
          data.forEach(doc => {
            projects.push({
              projectId: doc.id ? doc.id : null,
              name: doc.data().name ? doc.data().name : null,
              teamId: doc.data().teamId ? doc.data().teamId : null,
              teamname: doc.data().teamname ? doc.data().teamname : null,
              score: doc.data().score ? doc.data().score : 0,
              noteCount: doc.data().noteCount ? doc.data().noteCount : 0,
              score: doc.data().score ? doc.data().score : null,
                videoscore: doc.data().videoscore ? doc.data().videoscore : null,
                summaryscore: doc.data().summaryscore ? doc.data().summaryscore : null,
                dl: doc.data().dl ? doc.data().dl : null,
                comment: doc.data().comment ? doc.data().comment : null,
                country: doc.data().country ? doc.data().country : null,
              createdAt: doc.data().createdAt ? doc.data().createdAt : null
            });

            return projects;
          });
          dispatch({ type: SET_PROJECTS_RANKING, payload: projects });
        });
    } catch (error) {}
  };
};

// Get specific Ranking
// export const getRanking = rankingId => {
//     return (dispatch, getState) => {
//       // make async call to database
//       let rankingData = {};
//       const docRef = db.collection("rankings").doc(rankingId);

//       dispatch({ type: LOADING_RANKING });

//       docRef.get()
//         .then(doc => {
//         // if (!doc.exists) {
//         //   console.log("File do not exist !");
//         // }
//         rankingData = doc.data();
//         rankingData.rankingId = doc.id;
//         // collect data
//         dispatch({ type: SET_RANKING, payload: rankingData });
//         dispatch({ type: STOP_LOADING_RANKING });
//       })
//       .catch((error) => {
//         // console.log("Error getting document:", error);
//         dispatch(showSuccessSnackbar("Error get ranking !"));
//       });

//     };
// };

// Realtime
export const getRanking = rankingId => {
  return (dispatch, getState) => {
    // make async call to database
    let rankingData = {};
    dispatch({ type: LOADING_RANKING });

    try {
      db.collection("rankings")
        .doc(rankingId)
        .onSnapshot(doc => {
          // if (!doc.exists) {
          //   console.log("File do not exist !");
          // }
          rankingData = doc.data();
          rankingData.rankingId = doc.id;
          // collect data
          dispatch({ type: SET_RANKING, payload: rankingData });
          dispatch({ type: STOP_LOADING_RANKING });
        });
    } catch (error) {
      // console.log("Error getting document:", error);
      dispatch(showSuccessSnackbar("Error get score !"));
    }
  };
};

  // Delete Ranking
  export const deleteRanking = rankingId => {
    return (dispatch, getState) => {
      // make async call to database
      // const firebaseData = getState().firebase;
      // Get current document
      const rankingDocument = db.collection("rankings").doc(rankingId);

      rankingDocument
        .update({
          softDelete: true
        })
        .then(() => {
          dispatch(showSuccessSnackbar("Ranking deleted successfully"));
        })
        .catch(err => {
          // console.error(err);
          dispatch(
            showSuccessSnackbar("Ranking delete error, please try again !")
          );
        });
    };
  };

  /**
   * TODO: GENERAL ACTIONS
   **/
  // Clear error
  export const clearErrors = () => dispatch => {
    dispatch({ type: CLEAR_ERRORS });
  };
