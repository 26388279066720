import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    STOP_LOADING_UI,
    SNACKBAR_SUCCESS,
    SNACKBAR_CLEAR
  } from "../Types";

  const initialState = {
    loading: false,
    errors: null,
    successSnackbarMessage: "",
    successSnackbarOpen: false,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_ERRORS:
        return {
          ...state,
          loading: false,
          errors: action.payload
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          loading: false,
          errors: null
        };
      case LOADING_UI:
        return {
          ...state,
          loading: true
        };
      case STOP_LOADING_UI:
        return {
          ...state,
          loading: false
        };
      case SNACKBAR_SUCCESS:
        return {
          ...state,
          successSnackbarOpen: true,
          successSnackbarMessage: action.message
        };
      case SNACKBAR_CLEAR:
        return {
          ...state,
          successSnackbarOpen: false,
          errorSnackbarOpen: false,
          infoSnackbarOpen: false
        };
      default:
        return state;
    }
  }
