import {
    LOADING_RANKING,
    STOP_LOADING_RANKING,
    SET_RANKINGS,
    SET_RANKING,
    SET_PROJECTS_RANKING
  } from "../Types";

  const initialState = {
    rankings: [],
    ranking: {},
    rankingsProject: [],
    loading: false
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function(state = initialState, action) {
    // console.log(state);

    switch (action.type) {
      case LOADING_RANKING:
        return {
          ...state,
          loading: true
        };
      case STOP_LOADING_RANKING:
        return {
          ...state,
          loading: false
        };
      case SET_RANKINGS:
        return {
          ...state,
          rankings: action.payload,
          loading: false
        };
      case SET_RANKING:
        return {
          ...state,
          ranking: action.payload,
          loading: false
        };
      case SET_PROJECTS_RANKING:
        return {
          ...state,
          rankingsProject: action.payload,
          loading: false
        };
      default:
        return state;
    }
  }
