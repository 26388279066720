import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { useHistory, Redirect } from "react-router-dom";
// Firebase config
import { storage } from "../../config/fire";
// Reusable component
import useForm from "../../Utils/UseForm";
import validate from "../../Utils/formValidations/validateCreateProject";
// MUI Stuff
import { makeStyles, ThemeProvider, createMuiTheme, } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// Redux stuff
import { connect } from "react-redux";
import { createProject } from "../../Redux/Actions/Project";
import { getTeams } from "../../Redux/Actions/Team";
import { showSuccessSnackbar } from "../../Redux/Actions/Snackbar";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0025FF",
  }},
});

const useStyles = makeStyles((theme) =>({
  root: {
    width: "70%",
    height: '100vh',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "default",
  },
  formItems: {
    color: "black",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "100%",
  },
  buttonStyle: {
    margin: theme.spacing(0, 0, 2),
    color: "white",
    backgroundColor: "#0025FF",
    height: "50px",
  },
  progressStyle: {
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#FF6400",
    height: "50px",
    width: "250px",
    marginLeft: "320px",
    fontSize: '20px',
  },
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

function CreateProject (props) {
  const {user, teams, project:{loading} } = props;
  // console.log(teams);
  // console.log(loading);
  const classes = useStyles();
  const [fileSummary, setFileSummary] = React.useState(null);
  const [urlSummary, setUrlSummary] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  // Get history callback func
  let history = useHistory();

// ComponentDidMount Hoock
  useEffect(() => {
    props.getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // Set default form values
    let formValues = {
      name: "",
      email: "",
      teamname: "",
      description: "",
      orgname: "",
      videoLink: "",
      summary: "",
    };
    // call use form
    const {
      handleChange,
      handleSubmit,
      values,
      errors
    } = useForm(submit, validate, formValues);

    // Redirect if User login
    if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // Handle Event File
  const handleFileChange = event => {
    const fileSummary = event.target.files[0];
    if (fileSummary) {
      // console.log(fileSummary);
      // Get file type
      // const fileType = fileSummary["type"];
      // console.log(fileType);
      // Get file size
      const img = fileSummary.size;
      const fileSize = img / 102400;
      // console.log(fileSize);

      // Verify and download
      if (fileSize > 102400) {
        props.showSuccessSnackbar(
          "Your file is too large. Max size is 100 megabyte"
        );
      } else {
        setFileSummary(fileSummary);
        // console.log(fileSummary);
        // Load file reader
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            // console.log(reader.result);
            setUrlSummary(reader.result);
          },
          false
        );
        reader.readAsDataURL(fileSummary);
      }
    }
  };
  // Edit file article
  const handleEditFile = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  // Submit Project
  function submit() {
        // Verify if file selected
        if (fileSummary) {
          // Upload file
          const uploadTask = storage.ref(`projects/${fileSummary.name}`).put(fileSummary);
          uploadTask.on(
            "state_changed",
            snapshot => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);
            },
            error => {
              //   setError(error);
              props.showSuccessSnackbar(error);
            },
            () => {
              // complete function ...
              storage
                .ref("projects")
                .child(fileSummary.name)
                .getDownloadURL()
                .then(urlSummary => {
                  setUrlSummary(urlSummary);
                  setProgress(0);
                  // fill data
                  const newProject = {
                    name: values.name.trim(),
                    teamId: null,
                    teamname: values.teamname.trim(),
                    email: values.email.trim(),
                    description: values.description.trim(),
                    orgname: values.orgname.trim(),
                    videoLink: values.videoLink.trim(),
                    summary: urlSummary,
                    userId: user.credentials.userId,
                    presentation: "",
                    model: ""
                  };
                  // Handling errors
                  // console.log(newProject);
                  props.createProject(newProject, history);
                  setUrlSummary("");
                  setFileSummary(null);
                });
            }
          );
        } else {
          props.showSuccessSnackbar("Please select an file for the project");
        }
  }

  return (
       <div className={classes.root}>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit} >
          <ThemeProvider theme={theme}>

              <FormControl className={classes.formControl} variant="outlined" error={errors.teamname ? true : false}>
                <InputLabel htmlFor="outlined-teamname-native-simple">Team name *</InputLabel>
                <Select
                  native
                  label="Team name *"
                  value={values.teamname}
                  onChange={handleChange}
                  inputProps={{
                    name: 'teamname',
                    id: 'outlined-teamname-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {teams && teams.map(team => (
                    <option value={team.teamname} key={team.teamId}>{team.teamname}</option>
                  ))}
                </Select>
                {errors.teamname ? <FormHelperText>required</FormHelperText> : null}
              </FormControl>

              <TextField
                variant="outlined"
                margin="normal"
                name="email"
                type="text"
                label="Email"
                placeholder="Email"
                className={classes.textField}
                error={errors.email ? true : false}
                helperText={errors.email}
                value={values.email}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                variant="outlined"
                margin="normal"
                name="name"
                type="text"
                label="Project name"
                placeholder="Project name"
                required
                className={classes.textField}
                error={errors.name ? true : false}
                helperText={errors.name}
                value={values.name}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="description"
                type="text"
                label="Project description"
                placeholder="Project description"
                required
                rows={4}
                variant="filled"
                className={classes.textField}
                error={errors.description ? true : false}
                helperText={errors.description}
                value={values.description}
                onChange={handleChange}
                fullWidth
                multiline
              />
              <TextField
                variant="outlined"
                margin="normal"
                name="orgname"
                type="text"
                label="Organisation name"
                placeholder="Organisation name"
                required
                className={classes.textField}
                error={errors.orgname ? true : false}
                helperText={errors.orgname}
                value={values.orgname}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="videoLink"
                type="text"
                variant="outlined"
                margin="normal"
                label="Video link"
                placeholder="Video link"
                required
                className={classes.textField}
                error={errors.videoLink ? true : false}
                helperText={errors.videoLink}
                value={values.videoLink}
                onChange={handleChange}
                fullWidth
              />

              <div style={{width: '100%', marginBottom: 5}}>
                  <LinearProgressWithLabel value={progress} />
              </div>

              <input
                type="file"
                id="fileInput"
                hidden="hidden"
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                color="default"
                required
                className={classes.buttonStyle}
                startIcon={!urlSummary ? <CloudUploadIcon /> : <AttachFileIcon/>}
                onClick={handleEditFile}
                fullWidth
              >
                {!urlSummary ? "Upload one page summary" : "A selected file, click to change"}
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading || teams.length === 0}
              >
                Next
                {loading && (
                    <CircularProgress
                      size={30}
                      className={classes.progressSpinner}
                    />
                  )}
              </Button>
            </ThemeProvider>
          </form>
        </div>
        )
};

CreateProject.propTypes = {
  createProject: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  project: state.projectData,
  teams: state.teamData.teams
});

const mapDispatchToProps = dispatch => {
  return {
    createProject: (newProject,historyCallback) => dispatch(createProject(newProject,historyCallback)),
    getTeams: () => dispatch(getTeams()),
    showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProject);