import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import icon from './../../../image/ranking.svg'
// Component
import Image from "../../../../Utils/Image";
// Redux Stuff
import { connect } from "react-redux";
import { getProjects } from "../../../../Redux/Actions/Ranking";

const useStyles = makeStyles({
  root: {
    // width: '100%',
    // marginLeft: 60,
    marginBottom: 10,
    // marginTop: 10,
  },
  cardMediaStyle: {
    paddingTop: '50%',
  },
  container: {
    maxHeight: 450,
  },
  image:{
    width: "40%",
    marginLeft:"25%"
  },
  text:{
    marginTop:30
  },
  head:{
    backgroundColor:"#FF5C00"
  }
});

function Score(props) {
  const { rankings: {rankingsProject} } = props;
  // console.log(rankingsProjec t);
  const classes = useStyles();

    // ComponentDidMount Hoock
    useEffect(() => {
      props.getProjects();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  // if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // const handleButtonClick = (pageURL) => {
  //   history.push(pageURL);
  // }; 
  function comment(xcomment){
    return{
      __html: xcomment
    };
  }

  return (
    <div>
      {/* <Image src={icon} className={classes.image}/>
      <Typography gutterBottom variant="h3" className={classes.text}>To Be Announced</Typography> */}

 
    <Typography variant="h5" className={classes.text}>Final Result</Typography> 
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              {/* <TableCell align="left">Rank</TableCell> */}
              <TableCell>Project name</TableCell>
              <TableCell align="left">Team name</TableCell>
              <TableCell align="left">Video Score(2-60)</TableCell>
              <TableCell align="left">Summary Score(2-30)</TableCell>
              <TableCell align="left">DT/WSLC Score(2-80)</TableCell>
              <TableCell align="left">Judges Score(s)</TableCell>
              <TableCell align="left">Final Score(s)(judges+public vote)</TableCell>
              <TableCell align="center">Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

          {rankingsProject.map((ranking, index) => (
            <TableRow key={ranking.projectId}>
              {/* <TableCell align="left">{index+1}</TableCell> */}
              <TableCell component="th" scope="row">
                {ranking.name}
              </TableCell>


              <TableCell align="left"><em>{ranking.teamname}</em></TableCell>
              <TableCell align="left">{ranking.videoscore}</TableCell>
              <TableCell align="left">{ranking.summaryscore}</TableCell>
              <TableCell align="left">{ranking.dl}</TableCell>
              <TableCell align="left">{ranking.judge}</TableCell>
              <TableCell align="left">{ranking.score}</TableCell>
              <TableCell align="leftt"> 
                <div dangerouslySetInnerHTML={comment(ranking.comment)}/> 
                {/* {ranking.comment} */}
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer> 
     
      <Typography variant="h6" className={classes.text}><strong>Public Vote Results:</strong> Congratulations to UV-Rover for being the fan favorite and receiving the most number of likes on Facebook. 3 points was added to their final score. 2nd place was ADOS so 2 points added to their final score, and 3rd place was LADƆNIYABAGA so 1 point added to their final score.</Typography> 
      
  
    </div>
  );
}

Score.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  rankings: state.rankingData
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Score));