import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// MUI Stuff
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
// Redux stuff
import { connect } from "react-redux";
import { getProject } from "../../Redux/Actions/Project";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    height: '100vh',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttonStyle: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#0025FF",
    height: "50px",
  },
  linkRoot: {
    '& > * + *': {
      marginLeft: 10,
    },
  }
  }));


function ProjectDetails (props) {
  const {project:{project} } = props;
  const {idProject} = useParams();
  const classes = useStyles();

  // ComponentDidMount Hoock
  useEffect(() => {
    props.getProject(idProject);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProject]);

  // Redirect if User login
  // if (!user.isAuthenticated || user.credentials.role !== 'judge') return <Redirect to="/signin" />;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
                {project.teamname}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
              <strong>Organization Name:</strong> {project.orgname} <br/>
                <strong>Project Name:</strong> {project.name} <br/>
                <strong>Description:</strong> {project.description} <br/>

                <span style={{fontWeight: "bold", display: "block", textDecoration: "underline", marginTop: 10}}>
                  Links :
                </span>
                <span className={classes.linkRoot}>
                  {project.videoLink &&
                  <Link href={project.videoLink} target="_blank">
                    video
                  </Link>}

                  {project.summary &&
                  <Link href={project.summary} target="_blank">
                    Summary
                  </Link> }

                  {project.presentation &&
                  <Link href={project.presentation} target="_blank">
                    Presentation
                  </Link> }
                  {project.presentation &&
                  <Link href={project.model} target="_blank">
                   Model
                  </Link> }
                </span>
              </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

ProjectDetails.propTypes = {
  getProject: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  auth: state.firebase.auth,
  project: state.projectData
});

const mapDispatchToProps = dispatch => {
  return {
    getProject: projectId => dispatch(getProject(projectId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetails);