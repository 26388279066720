export default function validateSignInDetails(values) {
    let errors = {};

    if (!values.username) {
      errors.username = "Username content is required";
    }

    if (!values.password) {
      errors.password = "Password content is required";
    }

    return errors;
  }
