import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { useParams, Redirect } from "react-router-dom";
// Firebase config
import { storage } from "../../config/fire";
// MUI Stuff
import { makeStyles, ThemeProvider, createMuiTheme, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
// Icons
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
// Redux stuff
import { connect } from "react-redux";
import { getProject, updateProjects } from "../../Redux/Actions/Project";
import { showSuccessSnackbar } from "../../Redux/Actions/Snackbar";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0025FF",
  }},
});

const useStyles = makeStyles((theme) =>({
  root: {
    width: "70%",
    height: '100vh',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "default",
  },
  formItems: {
    color: "black",
  },
  buttonStyle: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#0025FF",
    height: "50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#FF6400",
    height: "50px",
    width: "250px",
    marginLeft: "320px",
    fontSize: '20px',
  },
  linkRoot: {
    '& > * + *': {
      marginLeft: 10,
    },
  }
  }));

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{ fontSize: 10 }}
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

function UploadPresentationAndModel(props) {
  const {user, project:{project, loading} } = props;
  const {idProject} = useParams();
//   console.log(project);
  const preventDefault = (event) => event.preventDefault();
  // console.log(project);
  const classes = useStyles();
  // state that will hold the Array of objects
  // initialized with empty array
  const [files, setFiles] = React.useState([]);
  // button state whether it's disabled or enabled
  const [enabled, setEnabled] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  // ComponentDidMount Hoock
  useEffect(() => {
    props.getProject(idProject);
    if (files.length === 0) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProject, files]);

  // Redirect if User login
  if (!user.isAuthenticated) return <Redirect to="/signin" />;

    // onChange function that reads files on uploading them
    // files read are encoded as Base64
    function onFileUpload(event) {
        event.preventDefault();
        // Get the file Id
        let id = event.target.id;
        // Create an instance of FileReader API
        let file_reader = new FileReader();
        // Get the actual file itself
        let file = event.target.files[0];
        file_reader.onload = () => {
        // After uploading the file
        // appending the file to our state array
        // set the object keys and values accordingly
            setFiles([...files, { file_id: id, uploaded_file: file_reader.result, fileTarget: file }]);
        };
        // reading the actual uploaded file
        file_reader.readAsDataURL(file);
    }

    // handle submit button for form
    function handleSubmit(e) {
        e.preventDefault();
        const promises = [];
        files.forEach(file => {
            // console.log(file);
            // console.log(file.file_id);
            // console.log(file.fileTarget.name);
            // Upload file
            const uploadTask = storage.ref(`projects/${file.fileTarget.name}`).put(file.fileTarget);
            promises.push(uploadTask);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                error => {
                    //   setError(error);
                    props.showSuccessSnackbar(error);
                },
                async () => {
                    // complete function ...
                    await storage
                    .ref("projects")
                    .child(file.fileTarget.name)
                    .getDownloadURL()
                    .then(url => {
                        // Verify file between presentation or model
                        if(file.file_id === "1") {
                            // File presentation
                            const formData = {
                                presentation: url,
                            };
                            props.updateProjects(formData, project.projectId);
                            // console.log(formData);
                        } else if(file.file_id === "2") {
                            // File Model
                            const formData = {
                                model: url,
                            };
                            props.updateProjects(formData, project.projectId);
                            // console.log(formData);
                        } else {
                            return false
                        }
                        setProgress(0);
                    });
                }
            );
        });

        // Promises all process launch
        Promise.all(promises)
        .then(() => {
            // fill data
            props.showSuccessSnackbar("Project updated successfully !");
            // console.log("Files upload successfully")
        }).catch((error) => console.log(error))
    }

  // Markup formulare
  const formMarkup = !project.presentation || !project.model ? (<form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit} >
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{marginTop: 45}}>
            <Typography gutterBottom variant="h5">
                Download your Presentation and Model files
            </Typography>

            {!project.presentation ?
                (<Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: 10, fontSize: 14}}>Presentation file :</FormLabel>
                        <FormGroup>
                            <input
                            type="file"
                            id={1}
                            onChange={onFileUpload}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>) : null
            }

            {!project.model ?
                (<Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: 10, fontSize: 14}}>Model file :</FormLabel>
                        <FormGroup>
                            <input
                            type="file"
                            id={2}
                            onChange={onFileUpload}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>) : null
            }

            <div style={{width: '100%'}}>
                <LinearProgressWithLabel value={progress} />
            </div>
        </Grid>

        {enabled ? (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
            {loading && (
                <CircularProgress
                  size={30}
                  className={classes.progressSpinner}
                />
              )}
          </Button>
        ) : (
          <Button
            type="submit"
            disabled
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        )}


      </ThemeProvider>
    </form>): null;

// console.log(enabled);

  return (
       <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                {project.teamname}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <strong>Name:</strong> {project.name} <br/>
                <strong>Description:</strong> {project.description} <br/>

                <span style={{fontWeight: "bold", display: "block", textDecoration: "underline", marginTop: 10}}>
                  Links :
                </span>
                <span className={classes.linkRoot}>
                  {project.videoLink &&
                  <Link href={project.videoLink}  onClick={preventDefault}>
                    video
                  </Link>}

                  {project.summary &&
                  <Link href={project.summary} onClick={preventDefault} color="inherit">
                    Summary
                  </Link>}

                  {project.presentation &&
                  <Link href={project.presentation} onClick={preventDefault} variant="body2">
                    Presentation
                  </Link>}

                  {project.model &&
                  <Link href={project.model} onClick={preventDefault} variant="body2">
                    Model
                  </Link>}
                </span>
              </Typography>

              {formMarkup}
            </Grid>
          </Grid>
        </div>
        )
};

UploadPresentationAndModel.propTypes = {
  getProject: PropTypes.func.isRequired,
  showSuccessSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  project: state.projectData
});

const mapDispatchToProps = dispatch => {
  return {
    getProject: projectId => dispatch(getProject(projectId)),
    updateProjects: (projectDetails, projectId) => dispatch(updateProjects(projectDetails, projectId)),
    showSuccessSnackbar: message => dispatch(showSuccessSnackbar(message))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPresentationAndModel);