import {
    LOADING_PROJECT,
    STOP_LOADING_PROJECT,
    SET_PROJECTS,
    SET_PROJECT
  } from "../Types";

  const initialState = {
    projects: [],
    project: {},
    loading: false
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function(state = initialState, action) {
    // console.log(state);

    switch (action.type) {
      case LOADING_PROJECT:
        return {
          ...state,
          loading: true
        };
      case STOP_LOADING_PROJECT:
        return {
          ...state,
          loading: false
        };
      case SET_PROJECTS:
        return {
          ...state,
          projects: action.payload,
          loading: false
        };
      case SET_PROJECT:
        return {
          ...state,
          project: action.payload,
          loading: false
        };
      default:
        return state;
    }
  }
