import {
    SIGNUP_ERROR,
    SIGNUP_SUCCESS,
    SIGNIN_ERROR,
    SIGNIN_SUCCESS,
    SIGNOUT_ERROR,
    SIGNOUT_SUCCESS,
    LOGIN_REQUEST,
    STOP_LOGIN_REQUEST,
    SET_USER,
    SET_USER_DETAILS,
    SET_ERRORS_USER,
    CLEAR_ERRORS_USER,
    LOADING_USER,
  } from "../Types";

  const initialState = {
    isAuthenticated: false,
    isVerifying: false,
    credentials: {},
    loading: false,
    loginRequest: false,
    errors: null
  };

  // eslint-disable-next-line import/no-anonymous-default-export
  export default function(state = initialState, action) {
    switch (action.type) {
      case SIGNUP_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          loginRequest: false
        };
      case SIGNUP_ERROR:
        return {
          ...state,
          loginRequest: false,
          errors: { error: action.payload }
        };
      case SIGNIN_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          loginRequest: false
        };
      case SIGNIN_ERROR:
        return {
          ...state,
          loginRequest: false,
          errors: { error: action.payload }
        };
      case SIGNOUT_SUCCESS:
        return initialState;
      case SIGNOUT_ERROR:
        return {
          ...state,
          loginRequest: false,
          errors: action.payload
        };
      case SET_USER:
        return {
          isAuthenticated: true,
          isVerifying: true,
          loginRequest: false,
          loading: false,
          errors: null,
          ...action.payload
        };
      case SET_USER_DETAILS:
        return {
          ...state,
          isAuthenticated: true,
          isVerifying: true,
          loginRequest: false,
          credentials: action.payload,
          loading: false,
          errors: null
        };
      case LOADING_USER:
        return {
          ...state,
          loading: true
        };
      case LOGIN_REQUEST:
        return {
          ...state,
          loginRequest: true
        };
      case STOP_LOGIN_REQUEST:
        return {
          ...state,
          loginRequest: false
        };
      case SET_ERRORS_USER:
        return {
          ...state,
          loading: false,
          errors: action.payload
        };
      case CLEAR_ERRORS_USER:
        return {
          ...state,
          loading: false,
          loginRequest: false,
          errors: null
        };
        default:
      return state;
    }
  }