import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from "recompose/compose";
// MUI Stuff
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// Component
// import PARCVideo from "./PARCVideo.mp4";
import image from './../image/project.jpg';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // videoStyle: {
  //   position: "absolute",
  //   width: "100%",
  //   height:"100%",
  //   objectFit:"cover",
  //   zIndex: "-1"
  // }
}));

const SignInLayout = props => {
  const {children} = props;
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      {/* <video className={classes.videoStyle} autoPlay loop muted>
      <source src={PARCVideo} type='video/mp4' />
      </video> */}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {children}
        </div>
      </Grid>
    </Grid>
  );
}

export default compose(withRouter)(SignInLayout);