// Minify text function
function MinifyText(string = "Title event", size) {
    let final = "";
    if (string.length > size) {
      final = string.substr(0, size) + "...";
    } else {
      final = string;
    }
    return final;
  }

  export default MinifyText;
