export default function validateRanking(values) {
    let errors = {};
    if (!values.projectname) {
      errors.projectname = "required";
    }

    // if (!isNumber(values.video)) {
    //   errors.video = "Only accepts numbers";
    // }else if(values.video > 10 || values.video < 0) {
    //   errors.video = "Can be less than 2 or more than 10";
    // }

    // if (!isNumber(values.onePageSummary)) {
    //   errors.onePageSummary = "Only accepts numbers";
    // }else if(values.onePageSummary > 10 || values.onePageSummary < 0) {
    //   errors.onePageSummary = "Can be less than 2 or more than 10";
    // }

    if (!isNumber(values.empathize)) {
      errors.empathize = "Only accepts numbers";
    }else if(values.empathize > 10 || values.empathize < 0) {
      errors.empathize = "Can be less than 2 or more than 10";
    }

    if (!isNumber(values.define)) {
      errors.define = "Only accepts numbers";
    }else if(values.define > 10 || values.define < 0) {
      errors.define = "Can be less than 2 or more than 10";
    }

    if (!isNumber(values.ideate)) {
      errors.ideate = "Only accepts numbers";
    }else if(values.ideate > 10 || values.ideate < 0) {
      errors.ideate = "Can be less than 2 or more than 10";
    }

    if (!isNumber(values.model)) {
      errors.model = "Only accepts numbers";
    }else if(values.model > 10 || values.model < 0) {
      errors.model = "Can be less than 2 or more than 10";
    }

    // PROBLEM DEFINITION
    if (!isNumber(values.proDefinition)) {
      errors.proDefinition = "Only accepts numbers";
    }else if(values.proDefinition > 10 || values.proDefinition < 0) {
      errors.proDefinition = "Can be less than 2 or more than 10";
    }

    // LIFE CYCLE PRIORITIES CONSIDERATION
    if (!isNumber(values.lifPriorities)) {
      errors.lifPriorities = "Only accepts numbers";
    }else if(values.lifPriorities > 10 || values.lifPriorities < 0) {
      errors.lifPriorities = "Can be less than 2 or more than 10";
    }

    // WHOLE SYSTEMS SOLUTION CONSIDERATION
    if (!isNumber(values.whoSolution)) {
      errors.whoSolution = "Only accepts numbers";
    }else if(values.whoSolution > 10 || values.whoSolution < 0) {
      errors.whoSolution = "Can be less than 2 or more than 10";
    }

    // REFLECT ON REDESIGN SOLUTION
    if (!isNumber(values.refSolution)) {
      errors.refSolution = "Only accepts numbers";
    }else if(values.refSolution > 10 || values.refSolution < 0) {
      errors.refSolution = "Can be less than 2 or more than 10";
    }

    return errors;
  }


  function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
  // function isGreatAndLest(n) {
  //   if(n > )
  // }
  // The same using regex:
  // function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }