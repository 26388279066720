import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
// MUI Stuff
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// Icons
import icon from './../image/P_icon.svg'
// import ArrowLeft from "@material-ui/icons/ArrowLeft";
// Components
import MinifyText from '../../Utils/MinifyText';
import Image from "../../Utils/Image";
// Redux stuff
import { connect } from "react-redux";
import { getProjects } from "../../Redux/Actions/Project";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  content:{
    display: 'block',
    marginRight: 'auto'
  },
  noproject:{
    margin: "0 auto",
    textAlign: "center"
  },
  linkRoot: {
    '& > * + *': {
      marginLeft: 10,
    },
  }
});

function Projects(props) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { user, history, data:{loading, projects} } = props;
  // console.log(props);
  // ComponentDidMount Hoock
  useEffect(() => {
    props.getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  Redirect if User login
  // if (!user.isAuthenticated) return <Redirect to="/signin" />;

  // const handleButtonClick = (pageURL) => {
  //   history.push(pageURL);
  // };

  const markupProjectsList = projects.length !== 0 ? projects.map(project =>
  <Grid item md={3} xs={12} key={project.projectId}>
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image={project.picture}
          title="Contemplative Reptile"
        /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {project.teamname}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <strong>Organization Name: {project.orgname} </strong> <br/>
                <strong>Project Name:</strong> {project.name} <br/>
            <strong>Description:</strong> {MinifyText(project.description, 60)} <br/>

            <span style={{fontWeight: "bold", display: "block", textDecoration: "underline", marginTop: 10}}>
              Links :
            </span>
            <span className={classes.linkRoot}>
              {project.videoLink &&
              <Link href={project.videoLink}  >
                video
              </Link>}
{/*
              {project.summary &&
              <Link href={project.summary}color="inherit">
                Summary
              </Link> }

              {project.presentation &&
              <Link href={project.presentation}  variant="body2">
                Presentation
              </Link> }

              {project.model &&
              <Link href={project.model}  variant="body2">
                Presentation
              </Link> }*/}
            </span> 
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
        {/* <Button size="small" color="primary" onClick={() => handleButtonClick(`details/${project.projectId}`) }>
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  </Grid>) :<div className={classes.noproject}>  <Image alt="Project Image" src={icon} width="50%"/>  <Typography>  No project found yet</Typography></div> ;

  return (
    <div className={classes.content}>
      <Grid container spacing={2}>
        {markupProjectsList}
      </Grid>
    </div>

  )
};

Projects.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  auth: state.firebase.auth,
  data: state.projectData
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects));
